/* eslint-disable no-eval */
/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useDebounce} from 'use-debounce';
import {EmptyContent, Modal, ScrollList, View} from '..';
import Utils from '../../helpers/Utils';
import { useTranslation } from 'react-i18next';

const FormInputTag = React.forwardRef(({
  name,
  items,
  itemsFromUrl,
  violation,
  labelSelector,
  idSelector,
  containerStyle,
  inputStyle = {},
  multiple=true,
  value,
  onChange,
  renderItem,
  headerComponent,
  onBlur,
}, ref) => {
  const {t} = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [search, setSearch] = useState(null);
  const [searchDebounced] = useDebounce(search, 800);
  const [tags, setTags] = useState([]);
  const [changeDetector, setChangeDetector] = useState('');

  const prepareUrl = (qSearch) => {
    if (
      itemsFromUrl &&
      itemsFromUrl.url &&
      itemsFromUrl.params &&
      itemsFromUrl.paginationIndex
    ) {
      var searchData = [];

      if (qSearch) {
        itemsFromUrl.searchingFilter.map(key => {
          //searchData.push(key + '[]=' + qSearch);
          searchData[key] = qSearch;
        });
      }

      itemsFromUrl.params[itemsFromUrl.filterIndex] = searchData;
    }
  }

  // Set default tag
  const setDefaultValue = () => {
    if (value) {
      var formatDefaultValue = [];
      var values = value;

      if (!Utils.isArray(values)) {
        values = [value];
      }

      for (var v in values) {
        var object = values[v];

        var tagObject = {id: object[idSelector]};

        tagObject[idSelector] = object[idSelector];
        tagObject[labelSelector] = object[labelSelector]

        formatDefaultValue.push(tagObject);
      }

      setTags(formatDefaultValue);
    }
  };

  // Add tag
  const addTag = item => {
    let tagFound = tags.find(tag => {
      return tag[idSelector] === item[idSelector];
    });

    if (!tagFound) {
      var tagObject = {id: item[idSelector]};

      tagObject[idSelector] = item[idSelector];
      tagObject[labelSelector] = Utils.objectProperty(item, labelSelector);

      if (multiple) {
        setTags([...tags, tagObject]);
      } else {
        setTags([tagObject]);
      }
    }

    setModalVisible(false);
  };

  // Remove tag
  const removeTag = async tagToRemove => {
    let remainingTags = tags.filter(tag => {
      return tag[idSelector] !== tagToRemove[idSelector];
    });

    setTags(remainingTags);
  };

  const renderItemFormat = ({item, index}) => (
    renderItem ? renderItem(item, addTag) :
    <div key={item[idSelector]} onClick={() => addTag(item)}>
      <span style={{fontSize: 16, fontWeight: 'bold'}}>
        {Utils.objectProperty(item, labelSelector)?.toString().trim()}
      </span>
    </div>
  )

  // EFFECT

  // Default value
  useEffect(() => {
    setDefaultValue();
  }, [value]);

  // SearchText
  useEffect(() => {
    prepareUrl(searchDebounced);
  }, [searchDebounced]);

  // Change detector
  useEffect(() => {
    var detectOnChange = '';

    tags.map(tag => {
      detectOnChange = detectOnChange + '' + tag[idSelector];
    });

    if (changeDetector !== detectOnChange) {
      setChangeDetector(detectOnChange);
    }
  }, [tags]);

  // Update HookForm
  useEffect(() => {
    var formatedDatas = [];

    tags.map(tag => {
      var tagObject = {id: tag[idSelector]};

      tagObject[idSelector] = tag[idSelector];
      tagObject[labelSelector] = tag[labelSelector];

      formatedDatas.push(tagObject);
    });

    onChange && onChange(multiple ? formatedDatas : (formatedDatas?.length > 0 ? formatedDatas[0] : null));
  }, [changeDetector]);

  return (
    <>
      <ul className="form-control form-control-dark" style={{padding: 0, margin: 0}}>
        {tags.map(tag => {
          return (
            <li key={tag[idSelector]} className="btn btn-outline-warning mg-r-3" onClick={() => removeTag(tag)}>
              {tag[labelSelector]}
              <span className="remove">&nbsp;x</span>
            </li>
          );
        })}
        <li className="btn btn-outline-success pd-x-20" onClick={() => setModalVisible(true)}>+</li>

        <Modal show={modalVisible} handleClose={() => setModalVisible(false)}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 pd-y-20 bg-dark tx-white">
                {headerComponent && headerComponent()}
                <div className="row">
                  <div className="col-12">
                    <div className="form-layout form-layout-3">
                      <div className="row no-gutters">
                        <div className="col-12">
                          <div className="form-group">
                            <div className="input-group transition">
                              <span className="input-group-btn mg-r-60">
                                <i className="fa fa-search pos-absolute l-5 t-0" style={{fontSize: 40}}></i>
                              </span>
                              <input 
                                className="form-control" 
                                type="text" 
                                name="q" 
                                placeholder="Search..." 
                                value={search ? search : ''}
                                onChange={(e) => setSearch(e.target.value)}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mg-t-20">
                  <div className="col-12" style={{height: 600, overflowY: 'auto'}}>
                    <ScrollList
                      urlCaller={{
                        url: itemsFromUrl?.url,
                        params: itemsFromUrl?.params,
                        paginationIndex: itemsFromUrl?.paginationIndex,
                      }}
                      renderItem={renderItemFormat}
                      numColumns={"col-12"}
                      extraData={searchDebounced}
                      ListEmptyComponent={
                        <View className='col-12'>
                          <EmptyContent text={t('fe.no.result.description')} />
                        </View>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </ul>
      {violation && <span className='text-danger'>{violation?.message}</span>}
    </>
  );
});

export default FormInputTag;
