import { api } from "./configs/fetchConfig";
import { fetchUtils } from "./configs/fecthUtils";
import { passwordTokenSchema, passwordUpdateSchema, userRegistrationPasswordSchema, userSchema } from "./configs/schema";

export const UserAPI = {
    getInfo: async () => {
        return await api.fetchResource('/api/userinfo', {
            method: 'GET',
        });
    },
    get: async id => {
        return await api.fetchResource('/api/users/' + id, {
            method: 'GET',
        });
    },
    registration: async data => {
        return await api.fetchResource('/api/users/registration_request', {
            method: 'POST',
            body: JSON.stringify(fetchUtils.bodyFormat(data, userSchema)),
        });
    },
    passwordToken: async data => {
        return await api.fetchResource('/api/password/token', {
            method: 'POST',
            body: JSON.stringify(fetchUtils.bodyFormat(data, passwordTokenSchema)),
        });
    },
    registrationNickname: async data => {
        return await api.fetchResource('/api/users/registration_nickname', {
            method: 'POST',
            body: JSON.stringify(fetchUtils.bodyFormat(data, userRegistrationPasswordSchema)),
        });
    },
    forgottenPassword: async data => {
        return await api.fetchResource('/api/users/forgotten_password_request', {
            method: 'POST',
            body: JSON.stringify(fetchUtils.bodyFormat(data, userSchema)),
        });
    },
    registrationPassword: async data => {
        return await api.fetchResource('/api/users/registration_password', {
            method: 'POST',
            body: JSON.stringify(fetchUtils.bodyFormat(data, userRegistrationPasswordSchema)),
        });
    },
    updateNickname: async (id, data) => {
        return await api.fetchResource('/api/users/' + id + '/nickname/update', {
            method: 'PUT',
            body: JSON.stringify(data),
        });
    },
    updateEmail: async (id, data) => {
        return await api.fetchResource('/api/users/' + id + '/email/update', {
            method: 'PUT',
            body: JSON.stringify(data),
        });
    },
    updatePassword: async (id, data) => {
        return await api.fetchResource('/api/users/' + id + '/password/update', {
            method: 'PUT',
            body: JSON.stringify(fetchUtils.bodyFormat(data, passwordUpdateSchema)),
        });
    },
    updateInfo: async (id, data) => {
        return await api.fetchResource('/api/users/' + id + '/info/update', {
            method: 'PUT',
            //body: JSON.stringify(data),
            body: JSON.stringify(fetchUtils.bodyFormat(data, userSchema)),
        });
    },
}