/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import { Loader} from '../../components';
import useActiveLink from '../../hooks/useActiveLink';
import { useTranslation } from 'react-i18next';

const AccountPreferences = () => {
  // SET ACTIVELINK
  useActiveLink('accountLayout', 'preferences');

  const {t} = useTranslation();

  const [user, setUser] = useState(null);
  const [inputs, setInputs] = useState();
  const [activity, setActivity] = useState(true);

  const auth = useSelector(state => {
    return state.auth;
  });

  const handleFormChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs(values => ({...values, [name]: value}));
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    console.log(inputs);
  }
  
  useEffect(() => {
    setActivity(true);

    setActivity(false);
  }, [auth]);

  return (
    activity ? <Loader /> :
      <section className="bg-black-1 pd-30 ht-100p">
        <h3 className="tx-center pd-b-10">Preferences</h3>
        <p className="tx-center mg-b-60">Update your Preferences.</p>
        <div class="mg-t-60">
          <label class="sidebar-label pd-x-25 mg-t-25">Quick Settings</label>

          <div class="sidebar-settings-item">
            <h6 class="tx-13 tx-normal">Sound Notification</h6>
            <p class="op-5 tx-13">Play an alert sound everytime there is a new notification.</p>
            <div class="br-switchbutton checked">
              <input type="hidden" name="switch1" value="true"/>
              <span></span>
            </div>
          </div>
          <div class="sidebar-settings-item">
            <h6 class="tx-13 tx-normal">2 Steps Verification</h6>
            <p class="op-5 tx-13">Sign in using a two step verification by sending a verification code to your phone.</p>
            <div class="br-switchbutton">
              <input type="hidden" name="switch2" value="false"/>
              <span></span>
            </div>
          </div>
          <div class="sidebar-settings-item">
            <h6 class="tx-13 tx-normal">Location Services</h6>
            <p class="op-5 tx-13">Allowing us to access your location</p>
            <div class="br-switchbutton">
              <input type="hidden" name="switch3" value="false"/>
              <span></span>
            </div>
          </div>
          <div class="sidebar-settings-item">
            <h6 class="tx-13 tx-normal">Newsletter Subscription</h6>
            <p class="op-5 tx-13">Enables you to send us news and updates send straight to your email.</p>
            <div class="br-switchbutton checked">
              <input type="hidden" name="switch4" value="true"/>
              <span></span>
            </div>
          </div>
          <div class="sidebar-settings-item">
            <h6 class="tx-13 tx-normal">Your email</h6>
            <div class="pos-relative">
              <input type="email" name="email" class="form-control" value="janedoe@domain.com"/>
            </div>
          </div>

          <div class="pd-y-20 pd-x-25">
            <h6 class="tx-13 tx-normal tx-white mg-b-20">More Settings</h6>
            <a href="" class="btn btn-block btn-outline-secondary tx-uppercase tx-11 tx-spacing-2">Account Settings</a>
            <a href="" class="btn btn-block btn-outline-secondary tx-uppercase tx-11 tx-spacing-2">Privacy Settings</a>
          </div>
        </div>
      </section>
  )
}

export default AccountPreferences;