import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {Loader, Media, NiceDropdown} from '..';
import { useTranslation } from 'react-i18next';

const AccountMenu = () => {
  const [user, setUser] = useState(null);
  const {t} = useTranslation();
  const [activity, setActivity] = useState(true);

  const menus = [
    {identifier: 'profile', pathname: '/account/profile', label: t('fe.account.profile.menu'), enable: true},
    {identifier: 'nickname', pathname: '/account/nickname', label: t('fe.account.change.nickname.menu'), enable: true},
    {identifier: 'email', pathname: '/account/email', label: t('fe.account.change.email.menu'), enable: true},
    {identifier: 'password', pathname: '/account/password', label: t('fe.account.change.password.menu'), enable: true},
    //{identifier: 'preferences', pathname: '/account/preferences', label: 'Preferences'},
  ];

  const auth = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    setActivity(true);

    setUser(auth?.user);

    setActivity(false);
  }, [auth]);

  return (
    activity ? <Loader /> :
    <div>
      <div className="tx-center">
        <div className='d-flex align-items-center justify-content-center'>
          <Media mediaUri={user?.avatar?.contentUrl} avatar={true} size={150} />
        </div>
        <h4 className="tx-normal tx-white tx-roboto mg-t-20 mg-b-2">{user?.nickname}</h4>
        <p className="mg-b-20">{user?.description}</p>
        {/*
        <p className="mg-b-25 tx-20">
          <a href="" className="tx-primary mg-r-5"><i className="fab fa-facebook-official"></i></a>
          <a href="" className="tx-info mg-r-5"><i className="fab fa-twitter"></i></a>
          <a href="" className="tx-danger"><i className="fab fa-pinterest"></i></a>
        </p>
        */}
      </div>
      <div className="mg-y-10">
        <NiceDropdown data={menus} scope='accountLayout'/>
      </div>
    </div>
  )
}

export default AccountMenu;