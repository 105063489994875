var AuthToken = (function() {
    var token = null;

    var getToken = async () => {
        return token;
    }

    var setToken = async newToken => {
        token = newToken;
    }

    var getRefreshToken = async () => {
        return localStorage.getItem("refreshToken");
    }

    var setRefreshToken = async (newRefreshToken) => {
        localStorage.setItem("refreshToken", newRefreshToken);
    }

    var unsetTokens = async () => {
        token = null;
        localStorage.removeItem("refreshToken");

        return true;
    }

    return {
        getToken: getToken,
        setToken: setToken,
        getRefreshToken: getRefreshToken,
        setRefreshToken: setRefreshToken,
        unsetTokens: unsetTokens,
    }
})();

export default AuthToken;