import React from 'react';

const ScrollListHeader = ({titleComponent, infoBarShotcut = false, orderComponent, filterComponent}) => {
  // Page title
  const pageTitle = () => {
    return (
      <>
        {titleComponent}
        <div className="btn-group pos-absolute r-30">
          {orderComponent}
          {filterComponent}
        </div>
      </>
    );
  }

  return (
    <>
      <div className="br-pagetitle pd-x-15 bg-black-1">
        {pageTitle()}
      </div>
      <div className={`pos-fixed t-35 pd-t-35 pd-b-10 l-0 r-0 bg-black-9 ${infoBarShotcut ? 'd-block' : 'd-none'}`} style={{zIndex: 1000}}>
        <div className="container">
            <div className="row">
              <div className="col">
                <div className="br-pagetitle pd-x-15">
                  {pageTitle()}
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default ScrollListHeader;
