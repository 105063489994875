/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';

const Topic = ({
  domain,
  title,
  description,
  textPosition = 'left',
  mediaType,
  mediaPath,
  mediaPosition = 'left',
}) => {

  // RENDER
  return (
    <div className='container pd-0'>
      <div className={`${textPosition === 'center' ? "text-center" : ""} row no-gutters widget-3 rounded`}>
        {mediaPosition === 'left' && mediaPath && description && (
          <div className="col-md-5 col-lg-6 col-xl-5">
            <figure className="ht-100 ht-md-100p">
              {mediaType === 'image' && <img src={mediaPath} className="img-fit-cover op-8" alt=""/>}
              {mediaType === 'video' &&  <ReactPlayer url={mediaPath} width='100%' height='100%'/>}
            </figure>
          </div>
        )}

        {description && (
          <div className={`${!mediaPath ? "col-12" : "col-md-7 col-lg-6 col-xl-7"} bg-br-primary pd-25-force d-flex align-items-start flex-column`}>
            {domain && <p className="tx-11 tx-mont tx-uppercase tx-semibold tx-success">{domain}</p>}
            {title && <h5 className="tx-normal tx-roboto tx-lg-16-force tx-xl-20-force lh-3 mg-b-15"><a href="" className="tx-white">{title}</a></h5>}
            <p className="tx-14 tx-gray-600 mg-b-auto">{description}</p>
            <span className="d-block mg-t-20 tx-13">Mar 11, 2017, 2:30pm</span>
          </div>
        )}

        {!description && mediaPath && (
          <div class="col-12">
            <figure className="ht-md-100p">
              {mediaType === 'image' && <img src={mediaPath} className="img-fit-cover op-8" alt=""/>}
              {mediaType === 'video' &&  <ReactPlayer url={mediaPath} width='100%' height='100%'/>}
            </figure>
          </div>
        )}

        {mediaPosition === 'right' && mediaPath && description && (
          <div className="col-md-5 col-lg-6 col-xl-5">
            <figure className="ht-100 ht-md-100p">
              {mediaType === 'image' && <img src={mediaPath} className="img-fit-cover op-8" alt=""/>}
              {mediaType === 'video' &&  <ReactPlayer url={mediaPath} width='100%' height='100%'/>}
            </figure>
          </div>
        )}
      </div>
    </div>
  )
}

export default Topic;