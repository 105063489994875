/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {GridList, ItemCard, ModalLink} from '..';
import { ItemAPI } from '../../apis/ItemAPI';
import useActivity from '../../hooks/useActivity';
import TabLinkedItemsMore from './TabLinkedItemsMore';
import { useTranslation } from 'react-i18next';

const TabLinkedItems = ({entity, onDataFetch}) => {
  const { t } = useTranslation();
  const activityHook = useActivity();
  const [totalItems, setTotalItems] = useState();
  const [paginator, setPaginator] = useState();

  // Linked items
  const [linkedItems, setLinkedItems] = useState();

  const fetchItemLinkedItems = async () => {
    var activityRef = activityHook.start();

    const response = await ItemAPI.getLinkedItems(entity.uuid);

    if (response.status) {
      setTotalItems(response?.totalItems);
      setPaginator(response?.paginator);

      setLinkedItems(response.payload);
    }

    activityHook.stop(activityRef);
  };

  // Effect
  useEffect(() => {
    fetchItemLinkedItems();
  }, []);

  useEffect(() => {
    linkedItems?.length > 0 && onDataFetch();
  }, [linkedItems])

  return (
    <div className='container pd-0'>
      <div className='row'>
        <div className="col mg-t-40 tx-center">
          <h6>
            {t("fe.itemSheet.tabLinkedItems.title").toUpperCase()}
          </h6>
        </div>
      </div>
      <div className='row'>
        <div className="col mg-t-20">
          <GridList
            data={linkedItems}
            numColumns={'col-lg-3 col-md-4 col-6'}
            renderItem={({item, index}) =>
              <div className="mb-3">
                <ModalLink
                  to={{
                    pathname: `/item-modal/item/details/${(item.parentUuid || item.uuid)}`,
                  }}>
                  <ItemCard data={item} overlay={true} />
                </ModalLink>
              </div>
            }
          />

          {paginator?.next && (
            <TabLinkedItemsMore entity={entity} />
          )}
        </div>
      </div>
    </div>
  )
}

export default TabLinkedItems;