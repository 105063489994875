/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {FlatList, Loader} from '..';
import useActivity from '../../hooks/useActivity';

const ScrollList = ({
  urlCaller,
  extraData, // Use extraData to RESTART (init) the scrollList
  onRefreshData, // Use onRefreshData(datas, setDatas) callBack to extra operation
  extraRefreshData, // Use extraRefreshData to REFRESH the scrollList
  onRefresh,
  renderItem,
  numColumns,
  inverted = false,
  onScroll,
  NoDataComponent,
  ListHeaderComponent,
  ListHeaderComponentStyle,
  ListEmptyComponent,
  loaderCenterScreen = true
}) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollDirection, setScrollDirection] = useState({
    drt: 'top',
    scrollTop: 0,
  });

  const [responseData, setResponseData] = useState();
  const activityHook = useActivity();
  const [isFetching, setIsFetching] = useState(false);
  const [forceLoading, setForceLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [lastPage, setLastPage] = useState(false);

  const [totalItems, setTotalItems] = useState();
  const [paginator, setPaginator] = useState();

  // ACTION & API
  // Call URL
  const callDatasUrl = async () => {
    var activityRef = activityHook.start();
    
    if (urlCaller?.url && urlCaller?.params) {
      var noPagination = false;

      if (urlCaller?.paginationIndex) {
        urlCaller.params[urlCaller.paginationIndex] = page;
      } else {
        noPagination = true;
        setLastPage(true);
      }

      const response = await urlCaller.url.apply(this, urlCaller.params);

      if (response.status) {
        setTotalItems(response?.totalItems);
        setPaginator(response?.paginator);

        var newResponseData = urlCaller?.transformData
          ? urlCaller?.transformData(response?.payload)
          : response?.payload;

        if (noPagination || page === 1) {
          setResponseData(newResponseData);
        } else {
          setResponseData([...responseData].concat(newResponseData));
        }

        // Last page reached
        if (response?.paginator?.next) {
          setPage(page + 1);
        } else {
          setLastPage(true);
        }
      }
    }

    setIsFetching(false);
    setForceLoading(false);

    activityHook.stop(activityRef);
  };

  // Reinitialize
  const fetchInitItems = () => {
    setScrollTop(0);
    setPage(1);
    setPages([]);
    setResponseData();
    setLastPage(false);
    setForceLoading(Math.random());
  };

  // Refresh handler
  const refreshHandler = () => {
    setIsFetching(true);
    fetchInitItems();
    onRefresh && onRefresh();
  };

  // Fetch more
  const fetchMoreItems = () => {
    if (!pages.includes(page) && !lastPage) {
      setPages([...pages].concat(page));
      setForceLoading(Math.random());
    }
  };

  // Scroll Handler
  const scrollHandler = contentOffsetY => {
    if (contentOffsetY > scrollDirection.scrollTop) {
      setScrollDirection({drt: 'bottom', scrollTop: contentOffsetY});
    }

    if (contentOffsetY <= scrollDirection.scrollTop) {
      setScrollDirection({drt: 'top', scrollTop: contentOffsetY});
    }
  };

  // EFFECT

  // EndPoint
  useEffect(() => {
    if (forceLoading === false) {
      fetchInitItems();
    }
  }, [extraData]);

  // Refresh
  useEffect(() => {
    responseData && 
      onRefreshData && 
      onRefreshData(responseData, setResponseData);
  }, [extraRefreshData]);

  // Scroll Top
  useEffect(() => {
    scrollHandler(scrollTop);
  }, [scrollTop]);

  // Scroll Direction
  useEffect(() => {
    onScroll && onScroll(scrollDirection);
  }, [scrollDirection]);

  // End Point
  useEffect(() => {
    if (lastPage === false && forceLoading !== false) {
      callDatasUrl();
    }
  }, [forceLoading]);

  // Item render
  const renderItemFormat = ({item, index}) => {
    return (
      <div key={index} className={numColumns}>
        {renderItem({item, index})}
      </div>
    );
  };
  return (
    activityHook.isLoading && page === 1 ? 
      <Loader centerScreen={loaderCenterScreen} /> : (
      <FlatList
        data={responseData}
        renderItem={renderItemFormat}
        className={'row'}
        ListHeaderComponent={ListHeaderComponent}
        ListHeaderComponentStyle={ListHeaderComponentStyle}
        ListEmptyComponent={() => ListEmptyComponent}
        onComponentScroll={(e) => setScrollTop(e)}
        FooterComponent={() => (
          activityHook.isLoading && (
            <div className="col-12 tx-center">
              <Loader centerScreen={false} />
            </div>
          )
        )}
        onEndReached={() => fetchMoreItems()}
      />
    )
  )
}

export default ScrollList;