/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {userLogin} from '../redux/Auth/auth-actions';
import {loaderStart, loaderEnd} from '../redux/Loader/loader-actions';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import { Loader, AccountMenu } from '../components';
import {
  NotFound,
  AccountProfile,
  AccountEmail,
  AccountNickname,
  AccountPassword,
  AccountPreferences,
} from '.';
import { Controller, useForm } from 'react-hook-form';
import { UserAPI } from '../apis/UserAPI';

const AccountLayout = () => {
  const location = useLocation();
  
  const [user, setUser] = useState(null);
  const [activity, setActivity] = useState(true);
  const [violations, setViolations] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector(state => {
    return state.auth;
  });

  // FORM
  const {
    control,
    handleSubmit,
    formState: {errors},
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      email: auth?.user?.email
    }
  });

  const onSubmit = async formData => {
    dispatch(loaderStart());

    var response = await UserAPI.updateEmail(formData);

    if (response.status) {
      dispatch(userLogin({user: response?.payload}));
    }

    // Violations
    setViolations(response?.violations);

    dispatch(loaderEnd());
  }
  
  useEffect(() => {
    setActivity(true);

    //setDefaultValues();

    setActivity(false);
  }, [auth]);

  return (
    activity ? <Loader /> :
      <section className="mg-t-80">
        <div className="row">
          <div className="col-12 col-lg-3">
            <AccountMenu />
          </div>
          <div className="col-12 col-lg-9">
            <Routes location={location?.state?.backgroundLocation || location}>
              <Route index path="profile" element={<AccountProfile />} />
              <Route path="nickname" element={<AccountNickname />} />
              <Route path="email" element={<AccountEmail />} />
              <Route path="password" element={<AccountPassword />} />
              <Route path="preferences" element={<AccountPreferences />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </section>
  )
}

export default AccountLayout;