import { useSelector } from "react-redux";
import {Navigate, Outlet} from "react-router-dom";
import useCheckRole from "../../hooks/useCheckRole";
import { NotFound } from "../../screens";

const ProtectedRoute = ({secured = true, role = null}) => {
  const auth = useSelector(state => {
    return state.auth;
  });

  const checkRole = useCheckRole();
  return (
    // Authenticated
    auth?.isLoggedIn ? (
      secured ? // If route secured check if user is allowed to access page
        (role === null || checkRole.isGranted(role) ? <Outlet /> : <NotFound />) 
        : <Navigate to={'/'} replace />
    ) : (
      // Not Authenticated
      secured ? <Navigate to={'/sign-in'} replace /> : <Outlet />
    )
  );
};

export default ProtectedRoute;
