/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import { CText, EmptyContent, Loader, View } from '../../components';
import { CollectionAPI } from '../../apis/CollectionAPI';
import useActivity from '../../hooks/useActivity';
import useActiveLink from '../../hooks/useActiveLink';
import { useTranslation } from 'react-i18next';

const Collections = ({collector, isCurrentUser}) => {
  const {t} = useTranslation();
  const activityHook = useActivity(true);

  // SET ACTIVELINK
  useActiveLink('collectionLayout', 'collections');

  const collectionItemState = useSelector(state => {
    return state.collectionItem;
  });

  const [collections, setCollections] = useState([]);
  const [totalItems, setTotalItems] = useState();

  const fetchCollection = async (userId) => {
    var activityRef = activityHook.start();

    const response = await CollectionAPI.getAll(userId);

    if (response.status) {
      var sections = [];
      var count = 0;

      response?.payload?.map(c => {
        if (c.totalItems > 0) {
          sections.push({title: c.translatedName, data: c.children});
          count = count + c.totalItems
        }
      });

      setTotalItems(count);

      setCollections(sections);
    }

    activityHook.stop(activityRef);
  };

  useEffect(() => {
    if (collector && isCurrentUser) {
      fetchCollection(collector.uuid);
    }
  }, [collectionItemState, collector]);

  useEffect(() => {
    if (collector && !isCurrentUser) {
      fetchCollection(collector.uuid);
    }
  }, [collector]);

  return (
    activityHook.isLoading ? <Loader centerScreen={false} /> : (
      <section>
        <div className="br-pagetitle mg-b-20-force pd-x-15 bg-black-1">
          <i className="icon ion-ios-search"></i>
          <div>
            <h4>
              {isCurrentUser ? 
                t('fe.collections.my.collections'):
                t('fe.collections.collector.collections', {collector: collector})
              }
            </h4>
            {totalItems > 0 && <p className="mg-b-0">{t('fe.collection.collections.total.object', {total: totalItems})}</p>}
          </div>
          {/*
          <div className="btn-group pos-absolute r-30">
            <button type="button" className="btn btn-outline-light rounded-0"><i className="fa fa-th"></i></button>
            <button type="button" className="btn btn-outline-light rounded-0"><i className="fa fa-th-list"></i></button>
          </div>
          */}
        </div>
        
        <div className="br-block-wrapper pd-20">
        {collections.length <= 0 ? (
          <EmptyContent text={t('fe.collections.no.items.description')} />
        ) : ( 
          collections.map(category => {
            return (
              <div key={category.title} className="row">
                <div className="col-12 pd-20">
                  <h5 className="tx-uppercase tx-15">{category.title}</h5>
                  <div className="row">
                  {category.data.map((subCategory) => {
                    return (
                      subCategory?.totalItems > 0 &&
                      <Link 
                        key={subCategory.id} 
                        className="col-md-4 col-lg-4 mg-b-20" 
                        to={`${!isCurrentUser ? '/collector/' + collector.uuid : ''}/collections/${subCategory.id}/items`}>
                        <div className="card bd-0 bg-royal">
                          <div className="pd-x-15 pd-y-10">
                            <div className="d-flex align-items-center">
                              <i className={`ion ${subCategory?.icon ? subCategory.icon : 'ion-earth'} tx-50 lh-0 tx-primary`}></i>
                              <div className="mg-l-20">
                                <div className="tx-12 tx-uppercase">
                                  {subCategory?.translatedName}
                                </div>
                                <div className="tx-20 tx-white tx-lato lh-1">{subCategory?.totalItems}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                  </div>
                </div>
              </div>
            )
          })
        )}
        </div>
      </section>
    )
  )
}

export default Collections;