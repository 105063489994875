/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Loader, SearchFilter, SearchOrder, ItemCard, ScrollList, ScrollListHeader, ModalLink, GridList, ProtectedScreen, View, EmptyContent} from '../../components';
import FormData from '../../helpers/FormData';
import useSearchFilter from '../../hooks/useSearchFilter';
import { CategoryAPI } from '../../apis/CategoryAPI';
import useActivity from '../../hooks/useActivity';
import { useTranslation } from 'react-i18next';

const Items = () => {
  // PARAMS
  const {id} = useParams();

  // HOOK
  const searchFilterHook = useSearchFilter(id, 'item');
  const activityHook = useActivity();
  const {t, i18n} = useTranslation();

  // STATE
  const [category, setCategory] = useState();
  const [filterFields, setFilterFields] = useState();
  const [sortFields, setSortFields] = useState();
  const [showInfoBar, setShowInfoBar] = useState(false);

  // ACTION & API
  const fetchCategory = async (id) => {
    var activityRef = activityHook.start();

    const response = await CategoryAPI.get(id);

    if (response?.status) {
      setCategory(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  // Filter fields
  const fetchCategorySearchFormFields = async categoryId => {
    var activityRef = activityHook.start();

    const response = await CategoryAPI.getSearchFormFields(categoryId, 1);

    if (response?.status) {
      setFilterFields(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  // Sort fields
  const fetchCategorySearchSortFields = async categoryId => {
    var activityRef = activityHook.start();

    const response = await CategoryAPI.getSearchSortFields(categoryId, 1);

    if (response?.status) {
      setSortFields(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  const onScroll = (event) => {
    const {drt, scrollTop} = event;

    if (drt === 'top' && scrollTop > 250) {
      setShowInfoBar(true);
    } else {
      setShowInfoBar(false);
    }
  };

  const pageTitle = () => {
    return (
      <>
        <i className={`icon ${category?.icon ? category.icon : 'ion-earth'}`}></i>
        <div>
          <h4>{category?.alias?.translatedName}</h4>
          <p className="mg-b-0">
            <Link to={{pathname: `/browse/${category?.alias?.parent?.id}`}}><span>{category?.alias?.parent?.translatedName}</span></Link>
          </p>
        </div>
      </>
    );
  }

  const renderItem = ({item}) => {
    return (
      <ModalLink key={item?.uuid} to={{pathname: `/item-modal/item/details/${item?.uuid}`}}>
        <ItemCard data={item} overlay={true}/>
      </ModalLink>
    )
  }

  // EFFECT

  // Endpoint : Id
  useEffect(() => {
    if (id) {
      fetchCategory(id);
      fetchCategorySearchFormFields(id);
      fetchCategorySearchSortFields(id);
    }
  }, [id]);

  return (
    activityHook.isLoading && !category? <Loader /> :
    <ProtectedScreen condition={category}>
      <section>
        <ScrollListHeader 
          titleComponent={pageTitle()}
          infoBarShotcut={showInfoBar}
          orderComponent={<SearchOrder hook={searchFilterHook} fields={sortFields} />}
          filterComponent={<SearchFilter hook={searchFilterHook} fields={filterFields} />}
        />
        <div className="br-pagebody">
          {category && category.level === 1 && (
            category?.children?.map((subCategory) => {
              return (
                <section key={subCategory?.id} className='mg-t-20'>
                  <div className='row'>
                    <div className='col-8'>
                      <h4 className="tx-info tx-light">{subCategory?.translatedName}</h4>
                    </div>
                    <div className='col-4 tx-right'>
                      <Link className='tx-white' to={{pathname: `/browse/${subCategory?.alias?.id}`}}>
                        {t('fe.items.show.more')}
                      </Link>
                    </div>
                  </div>
                  <ItemsSample category={subCategory} />
                </section>
              )
            })
          )}
          {category && category.level === 2 && (
            <div className="row">
              <div className="col-lg-12">
                <ScrollList
                  urlCaller={{
                    url: CategoryAPI.getItems,
                    params: [id, FormData.format(searchFilterHook.getFilters(), true), 1],
                    paginationIndex: 2,
                  }}
                  renderItem={renderItem}
                  extraData={searchFilterHook.getFilters() ?? id}
                  numColumns={"col-lg-2 col-md-4 col-sm-6 col-6 mb-3"}
                  ListEmptyComponent={
                    <View className='col-12'>
                      <EmptyContent text={t('fe.no.result.description')} />
                    </View>
                  }
                  onScroll={onScroll}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </ProtectedScreen>
  )
}

// Items samples
const ItemsSample = ({category}) => {
  const [items, setItems] = useState();

  const activityHook = useActivity();

  const fetchItemsSample = async categoryId => {
    var activityRef = activityHook.start();

    const response = await CategoryAPI.getWithItemSample(categoryId);

    if (response?.status) {
      setItems(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  useEffect(() => {
    category?.id && fetchItemsSample(category?.id);
  }, [category]);

  return (
    activityHook.isLoading ? <Loader centerScreen={false} /> :
    items?.length > 0 && <GridList
      data={items}
      numColumns={'col-lg-2 col-md-4 col-6 mb-3'}
      renderItem={({item, index}) =>
        <ModalLink
          key={index}
          to={{
            pathname: `/item-modal/item/details/${(item.uuid)}`,
          }}>
          <ItemCard data={item} overlay={true} />
        </ModalLink>
      }
    />
  )
}

export default Items;