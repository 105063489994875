/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import {ItemCard, Modal, ScrollList, View, Button, ModalLink} from '..';
import { ItemAPI } from '../../apis/ItemAPI';
import { useState } from 'react';

const TabGroupItemsMore = ({entity, groupIndex}) => {
  const { t } = useTranslation();

  // STATE
  const [showModal, setShowModal] = useState(false);
  
  return (
    <>
      <Button
        className={"btn btn-secondary mb-3"}
        title={t('fe.itemDetails.tabGroupItemsMore.showMore.button')}
        onClick={() => setShowModal(true)} 
      />

      <Modal 
        show={showModal} 
        handleClose={() => setShowModal(false)}
        fullscreen={true}
        centered={true}
        contentClassName={'col-12'}
        closeButton={true}
      >
        <View>
          <View className={'mb-3 tx-center pd-40'}>
            <h4 className='tx-info'>
              {t("fe.itemSheet.tabGroupItems.title")}
            </h4>
          </View>
          <ScrollList
            urlCaller={{
              url: ItemAPI.getGroupItems,
              params: [entity.uuid, groupIndex, [], 1],
              paginationIndex: 3,
            }}
            renderItem={({item, index}) =>
              <View style={{margin: 2}}>
                <ModalLink
                  to={{
                    pathname: `/item-modal/item/details/${(item.uuid)}`,
                  }}>
                  <ItemCard data={item} overlay={true} />
                </ModalLink>
              </View>
            }
            numColumns={"col-lg-2 col-md-3 mb-3"}
          />
        </View> 
      </Modal>
    </>
  )
}

export default TabGroupItemsMore;