/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import Dropdown from 'react-bootstrap/Dropdown';
import {useDispatch} from 'react-redux';
import {searchFilterOrder} from '../../redux/SearchFilter/search-filter-actions';
import {loaderStart, loaderEnd} from '../../redux/Loader/loader-actions';

const SearchOrder = ({hook, fields}) => {

  // DISPATCH
  const dispatch = useDispatch();

  // FORM
  const {
    control,
    handleSubmit,
    formState: {errors},
    getValues,
    setValue,
    watch,
  } = useForm();

  // On select
  const onSubmit = (orderBy/*, orderSort = 'asc'*/) => {
    dispatch(loaderStart());
    dispatch(searchFilterOrder(orderBy, hook.getIdentifier(), hook.getScope()));
    dispatch(loaderEnd());
  };

  // RENDER
  return (
    fields?.length > 0 &&
    <div className="btn-group">
      {/*
      <div className="btn btn-outline-light rounded-0" onClick={() => onSubmit(hook.getFilters()?.orderBy, hook.getFilters()?.orderSort === 'asc' ? 'desc' : 'asc')}>
        {hook.getFilters()?.orderSort === 'asc' ? <i className="fa fa-arrow-up"></i> : <i className="fa fa-arrow-down"></i>}
      </div>
      */}
      <Dropdown>
        <Dropdown.Toggle as={"div"} bsPrefix="p-0">
          <div className="btn btn-outline-light rounded-0">
            {fields.map((sortField) => {
              return ((hook.getFilters()?.orderBy ?? fields[0]?.formField?.reference) === sortField?.formField?.reference && sortField?.translatedName)
            })}
            <i className="fa fa-sort mg-l-10"></i>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu align={"end"}>
          <ul className="nav nav-effect nav-effect-4 flex-column" role="tablist">
            {fields.map((sortField, index) => {
              return (
                <Dropdown.Item key={index} as={"li"} className="nav-item mg-x-0-force" onClick={() => onSubmit(sortField?.formField?.reference/*, hook.getFilters()?.orderSort*/)}>
                  <div className={`nav-link wd-100p-force`}>
                    {sortField?.translatedName}
                  </div>
                </Dropdown.Item>
              )
            })}
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default SearchOrder;
