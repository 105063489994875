/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";

const FormInputTree = React.forwardRef(({
  name,
  data,
  height,
  idSelector,
  labelSelector,
  nextNodeSelector = 'children',
  value,
  onChange,
  onBlur,
  onFinish,
  submitOnSelect,
}, ref) => {
  const [items, setItems] = useState([]);
  const [selections, setSelections] = useState();

  // On change save selected
  const handleFormChange = (selectedItem) => {
    setSelections([selectedItem]);
  }

  // Fire callback
  const handleFormSubmit = () => {
    onChange && onChange(selections[0]);
  }

  // EndPoint
  useEffect(() => {
    data && setItems(data);
  }, [data])

  // EndPoint
  useEffect(() => {
    selections && submitOnSelect && handleFormSubmit();
  }, [selections])

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="categories-wrapper pd-y-20 bg-dark tx-white" >
            <div style={height && {height: height, overflowY: "auto"}}>
              {items && <TreeRow nextNode={nextNodeSelector} onBlur={onBlur} name={name} items={items} id={idSelector} label={labelSelector} value={value} handleFormChange={handleFormChange}/>}
            </div>
            {!submitOnSelect &&
              <div className="bg-black-4">
                <div className="container">
                  <div className="row">
                    <div className="col-12 pd-5">
                      <button className="btn btn-primary float-right" onClick={() => handleFormSubmit()}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
})

const TreeRow = ({nextNode = 'children', onBlur, name, items, label, id, value, handleFormChange}) => {
  return (
    items?.length > 0 && (
      <ul>
        {items.map((item) => {
          return (
            <li key={item[id]}>        
              <div>
                <span />
                <label>
                  <input
                    onBlur={onBlur}
                    type="radio"
                    name={name}
                    onClick={() => handleFormChange(item)}
                    defaultChecked={value && parseInt(value[id]) === parseInt(item[id])}
                  />
                  {label && <span className="mg-l-5">{item[label]}</span>}
                </label>
              </div>
              {item?.[nextNode] && <TreeRow nextNode={nextNode} onBlur={onBlur} name={name} items={item?.[nextNode]} id={id} label={label} value={value} handleFormChange={handleFormChange} />}
            </li>
          )
        })}
      </ul>
    )
  )
}

export default FormInputTree;