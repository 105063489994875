import { api } from "./configs/fetchConfig";
import { fetchUtils } from "./configs/fecthUtils";
import { itemLicenceSchema, itemSchema, itemReviewSchema } from "./configs/schema";

export const ItemAPI = {
    create: async data => {
      return await api.fetchResource('/api/items', {
        method: 'POST',
        body: JSON.stringify(fetchUtils.bodyFormat(data, itemLicenceSchema)),
      });
    },
    update: async (id, data) => {
      return await api.fetchResource('/api/items/' + id, {
        method: 'PUT',
        body: JSON.stringify(fetchUtils.bodyFormat(data, itemSchema)),
      });
    },
    updateLicence: async (id, data) => {
      return await api.fetchResource('/api/items/' + id + '/licence', {
        method: 'PUT',
        body: JSON.stringify(fetchUtils.bodyFormat(data, itemLicenceSchema)),
      });
    },
    getByCriteria: async (q = '', page = 1) => {
        var headers = new Headers();
        headers.set('Accept', 'application/ld+json');

        return await api.fetchResource('/api/items/' + q + '/search?page=' + page, {
            method: 'GET',
            headers: headers
        });
    },
    getBarcodeInfo: async (barcode, page = 1) => {
        return await api.fetchResource('/api/items/barcode/' + barcode + '/search?page=' + page, {
            method: 'GET',
        });
    },
    getAll: async () => {
      return await api.fetchResource('/api/items', {
          method: 'GET',
      });
    },
    get: async id => {
        return await api.fetchResource('/api/items/' + id, {
            method: 'GET',
        });
    },
    getProtected: async id => {
        return await api.fetchResource('/api/protected-items/' + id, {
            method: 'GET',
        });
    },
    getLinkedItems: async (itemId, filters = [], page = 1) => {
      const newFilters = filters.concat('page=' + page);

      return await api.fetchResource('/api/items/' + itemId + '/linked-items?' + newFilters.join('&'), {
          method: 'GET',
      });
    },
    getComposedItems: async (itemId, filters = [], page = 1) => {
      const newFilters = filters.concat('page=' + page);

      var headers = new Headers();
      headers.set('Accept', 'application/ld+json');

      return await api.fetchResource('/api/items/' + itemId + '/composed-items?' + newFilters.join('&'), {
        method: 'GET',
        headers: headers,
      });
    },
    getGroupItems: async (uuid, level = 1, filters = [], page = 1) => {
      const newFilters = filters.concat('page=' + page);

      var headers = new Headers();
      headers.set('Accept', 'application/ld+json');
      
      return await api.fetchResource('/api/items/' + uuid + '/item-groups/' + level + '/items?' + newFilters.join('&'), {
        method: 'GET',
        headers: headers
      })
    },
    getCollectors: async (itemId, filters = [], page = 1) => {
      const newFilters = filters.concat('page=' + page);

      return await api.fetchResource('/api/items/' + itemId + '/collectors?' + newFilters.join('&'), {
          method: 'GET',
      });
    },
    getReviews: async (itemId, filters = [], page = 1) => {
      const newFilters = filters.concat('page=' + page);

      return await api.fetchResource('/api/items/' + itemId + '/reviews?' + newFilters.join('&'), {
          method: 'GET',
      });
    },
    getCurrentUserReview: async (itemId, filters = [], page = 1) => {
      const newFilters = filters.concat('page=' + page);

      return await api.fetchResource('/api/items/' + itemId + '/current-user-review?' + newFilters.join('&'), {
          method: 'GET',
      });
    },
    getOthersUsersReviews: async (itemId, filters = [], page = 1) => {
      const newFilters = filters.concat('page=' + page);

      return await api.fetchResource('/api/items/' + itemId + '/others-users-reviews?' + newFilters.join('&'), {
          method: 'GET',
      });
    },
    createReview: async data => {
      return await api.fetchResource('/api/item-reviews', {
        method: 'POST',
        body: JSON.stringify(fetchUtils.bodyFormat(data, itemReviewSchema)),
      });
    },
    updateReview: async (id, data) => {
      return await api.fetchResource('/api/item-reviews/' + id, {
        method: 'PUT',
        body: JSON.stringify(fetchUtils.bodyFormat(data, itemReviewSchema)),
      });
    },
    getVerificationItems: async (filters = [], page = 1) => {
      const newFilters = filters.concat('page=' + page);

      var headers = new Headers();
      headers.set('Accept', 'application/ld+json');

      return await api.fetchResource('/api/protected/verification/items?' + newFilters.join('&'), {
        method: 'GET',
        headers: headers
      })
    },
}