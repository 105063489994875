import {fetchUtils} from './configs/fecthUtils';
import {api} from './configs/fetchConfig';
import {commonSearchSchema, itemSchema, itemGroupSchema} from './configs/schema';

export const CategoryAPI = {
  getAll: async () => {
    return await api.fetchResource('/api/categories');
  },
  get: async id => {
    return await api.fetchResource('/api/categories/' + id);
  },
  getWithItemSample: async id => {
    return await api.fetchResource('/api/categories/' + id + '/items-samples');
  },
  getAllProtected: async () => {
    return await api.fetchResource('/api/protected/categories');
  },
  getItems: async (category, filters = {}, page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, itemSchema)
      .join('&');

    let q = '?page=' + page + (query && '&' + query);

    var headers = new Headers();
        headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
      '/api/categories/' + category + '/search/items' + q,
      {
        method: 'GET',
        headers: headers
      },
    );
  },
  getLicences: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, itemGroupSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
      '/api/categories/' + category + '/search/item-groups' + q,
      {
        method: 'GET',
        headers: headers,
      },
    );
  },
  getBrands: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/brands' + q;
    } else {
      url = '/api/item-brands' + q;
    }

    return await api.fetchResource(url, {
      method: 'GET',
    });
  },
  getCompanies: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/companies' + q;
    } else {
      url = '/api/item-companies' + q;
    }

    return await api.fetchResource(url, {
      method: 'GET',
    });
  },
  getEditions: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/editions' + q;
    } else {
      url = '/api/item-editions' + q;
    }

    return await api.fetchResource(url, {
      method: 'GET',
    });
  },
  getFormats: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/formats' + q;
    } else {
      url = '/api/item-formats' + q;
    }

    return await api.fetchResource(url, {
      method: 'GET',
    });
  },
  getGenders: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/genders' + q;
    } else {
      url = '/api/item-genders' + q;
    }

    return await api.fetchResource(url, {
      method: 'GET',
    });
  },
  getBoxes: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/boxes' + q;
    } else {
      url = '/api/item-boxes' + q;
    }

    return await api.fetchResource(url, {
      method: 'GET',
    });
  },
  getContents: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/contents' + q;
    } else {
      url = '/api/item-contents' + q;
    }

    return await api.fetchResource(url, {
      method: 'GET',
    });
  },
  getLanguages: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/languages' + q;
    } else {
      url = '/api/item-languages' + q;
    }

    return await api.fetchResource(url, {
      method: 'GET',
    });
  },
  getZones: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/zones' + q;
    } else {
      url = '/api/item-zones' + q;
    }

    return await api.fetchResource(url, {
      method: 'GET',
    });
  },
  getLines: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/lines' + q;
    } else {
      url = '/api/item-lines' + q;
    }

    return await api.fetchResource(url, {
      method: 'GET',
    });
  },
  getTypes: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/types' + q;
    } else {
      url = '/api/item-types' + q;
    }

    return await api.fetchResource(url, {
      method: 'GET',
    });
  },
  getMaterials: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/materials' + q;
    } else {
      url = '/api/item-materials' + q;
    }

    return await api.fetchResource(url, {
      method: 'GET',
    });
  },
  getMechanisms: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/mechanisms' + q;
    } else {
      url = '/api/item-mechanisms' + q;
    }

    return await api.fetchResource(url, {
      method: 'GET',
    });
  },
  getNumbers: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/numbers' + q;
    } else {
      url = '/api/item-numbers' + q;
    }

    return await api.fetchResource(url, {
      method: 'GET',
    });
  },
  getPegis: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/pegis' + q;
    } else {
      url = '/api/item-pegis' + q;
    }

    return await api.fetchResource(url, {
      method: 'GET',
    });
  },
  getXContainers: async (category, filters = [], page = 1) => {
    return await api.fetchResource(
      '/api/categories/' +
        category +
        '/x-containers?page=' +
        page +
        '&' +
        filters.join('&'),
      {
        method: 'GET',
      },
    );
  },
  getXContainersFields: async (x_container_id, filters = [], page = 1) => {
    return await api.fetchResource(
      '/api/category-x-item-containers/' +
        x_container_id +
        '/fields?page=' +
        page +
        '&' +
        filters.join('&'),
      {
        method: 'GET',
      },
    );
  },
  getSearchFormFields: async (category, group) => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
      '/api/categories/' + category + '/field-group/' + group + '/search-form-fields',
      {
        method: 'GET',
        headers: headers,
      },
    );
  },
  getSearchSortFields: async (category, group) => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
        '/api/categories/' + category + '/field-group/' + group + '/search-sort-fields',
      {
        method: 'GET',
        headers: headers,
      },
    );
  },
  getProtectedSearchFormFields: async (category, group) => {
    return await api.fetchResource(
        '/api/categories/' + category + '/field-group/' + group + '/protected-search-form-fields',
      {
        method: 'GET',
      },
    );
  },
  getProtectedSearchSortFields: async (category, group) => {
    return await api.fetchResource(
        '/api/categories/' + category + '/field-group/' + group + '/protected-search-sort-fields',
      {
        method: 'GET',
      },
    );
  },
  bachUpdateProtectedSearchFormFields: async data => {
    return await api.fetchResource(
      '/api/category-x-search-form-fields/batch/protected-update',
      {
        method: 'POST',
        body: JSON.stringify({
          searchFormFields: data,
        }),
      },
    );
  },
  bachUpdateProtectedSearchSortFields: async data => {
    return await api.fetchResource(
      '/api/category-x-search-sort-fields/batch/protected-update',
      {
        method: 'POST',
        body: JSON.stringify({
          searchSortFields: data,
        }),
      },
    );
  },
  getProtectedXContainers: async (category, filters = [], page = 1) => {
    return await api.fetchResource(
      '/api/categories/' +
        category +
        '/protected-x-containers?page=' +
        page +
        '&' +
        filters.join('&'),
      {
        method: 'GET',
      },
    );
  },
  getProtectedXContainer: async (
    category_container_id,
    filters = [],
    page = 1,
  ) => {
    return await api.fetchResource(
      '/api/category-x-item-containers/' +
        category_container_id +
        '?page=' +
        page +
        '&' +
        filters.join('&'),
      {
        method: 'GET',
      },
    );
  },
  getProtectedXContainersFields: async (
    x_container_id,
    filters = [],
    page = 1,
  ) => {
    return await api.fetchResource(
      '/api/category-x-item-containers/' +
        x_container_id +
        '/protected-fields?page=' +
        page +
        '&' +
        filters.join('&'),
      {
        method: 'GET',
      },
    );
  },
  bachUpdateProtectedContainerXFields: async data => {
    return await api.fetchResource(
      '/api/category-container-x-form-fields/batch/protected-update',
      {
        method: 'POST',
        body: JSON.stringify({
          xFields: data,
        }),
      },
    );
  },
  bachUpdateProtectedXContainers: async data => {
    return await api.fetchResource(
      '/api/category-x-item-containers/batch/protected-update',
      {
        method: 'POST',
        body: JSON.stringify({
          xContainers: data,
        }),
      },
    );
  },
  createItemGroup: async (category, data) => {
    return await api.fetchResource('/api/categories/' + category + '/item-groups', {
      method: 'POST',
      body: JSON.stringify(fetchUtils.bodyFormat(data, itemSchema)),
    });
  },
};
