/* eslint-disable array-callback-return */
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import { useDispatch } from 'react-redux';
import {userLogin} from '../../redux/Auth/auth-actions';
import {loaderStart, loaderEnd} from '../../redux/Loader/loader-actions';
import { Controller, useForm } from 'react-hook-form';
import { UserAPI } from '../../apis/UserAPI';
import Alert from '../../helpers/Alert';
import useActiveLink from '../../hooks/useActiveLink';
import { useTranslation } from 'react-i18next';
import useSecurityCheck from '../../hooks/useSecurityCheck';

const AccountNickname = () => {
  // SET ACTIVELINK
  useActiveLink('accountLayout', 'nickname');

  const securityCheckHook = useSecurityCheck();

  const {t} = useTranslation();

  const dispatch = useDispatch();

  const [violations, setViolations] = useState([]);

  const auth = useSelector(state => {
    return state.auth;
  });

  // FORM
  const {
    control,
    resetField,
    handleSubmit,
  } = useForm({
    defaultValues: {
      nickname: auth?.user?.nickname
    }
  });

  const onSubmit = async formData => {
    dispatch(loaderStart());

    const response = await UserAPI.updateNickname(auth?.user?.uuid, formData);

    if (response?.status) {
      dispatch(userLogin({user: response?.payload}));

      Alert.notify(
        t('fe.account.nickname.form.onSubmit.alert.success.title'),
        t('fe.account.nickname.form.onSubmit.alert.success.description'),
        'success',
      );
    }

    // Violations
    setViolations(response?.violations);

    dispatch(loaderEnd());
  }

  return (
    <section className="bg-black-1 pd-30 ht-100p">
      <h3 className="tx-center pd-b-10">{t('fe.account.nickname.form.title')}</h3>
      <p className="tx-center mg-b-60">{t('fe.account.nickname.form.sub.title')}</p>
      {violations?.error?.message && <p className='tx-center text-danger'>{violations?.error?.message}</p>}
      {violations?.currentPassword?.message && <p className='tx-center text-danger'>{violations?.currentPassword?.message}</p>}
      <form className="form-layout form-layout-6">
        <div className="row no-gutters">
          <div className="col-5">
            <label className="form-control-label">{t('fe.account.nickname.form.nickname.label')}: <span className="tx-danger">*</span></label>
          </div>
          <div className="col-7">
            <Controller
              name="nickname"
              control={control}
              render={({ field }) => <input className="form-control" {...field} />}
            />
            {violations?.nickname?.message && <span className='text-danger'>{violations?.nickname?.message}</span>}
          </div>
        </div>
      </form>
      <div className="form-layout-footer bd pd-20 bd-t-0">
        <button className="btn btn-info" onClick={() => securityCheckHook.formAutorize('currentPassword', control, handleSubmit(onSubmit), resetField)}>{t('fe.account.nickname.form.submit.button')}</button>
      </div>
    </section>
  )
}

export default AccountNickname;