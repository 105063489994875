import React from 'react';
import { Link } from 'react-router-dom';
import Text from '../../helpers/Text';

const CategoryTreeLink = ({data, to}) => {
  return (
    data?.length > 0 && (
      <ul>
        {data?.map((category) => {
          return (
            <li key={category.id}>                          
              <div className={`pd-y-5 wd-100p-force active`}>
                <span></span>
                <Link to={Text.sprintf(to, category)}>{category?.translatedName}</Link>
              </div>
              {category?.children?.length > 0 && <CategoryTreeLink data={category?.children} to={to} />}
            </li>
          )
        })}
      </ul>
    )
  )
}

export default CategoryTreeLink;