import {fetchUtils} from './configs/fecthUtils';
import {api} from './configs/fetchConfig';
import { notificationSchema } from './configs/schema';

export const NotificationAPI = {
  get: async id => {
    return await api.fetchResource('/api/notifications/' + id, {
      method: 'GET',
    });
  },
  getAll: async () => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/notifications', {
      method: 'GET',
      headers: headers,
    });
  },
  getLatest: async () => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/notifications/latest/list', {
      method: 'GET',
      headers: headers,
    });
  },
  getUnreadList: async () => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/notifications/unread/list', {
      method: 'GET',
      headers: headers,
    });
  },
  update: async (id, data) => {
    return await api.fetchResource('/api/notifications/' + id, {
      method: 'PUT',
      body: JSON.stringify(fetchUtils.bodyFormat(data, notificationSchema)),
    });
  },
};
