/* eslint-disable react-hooks/exhaustive-deps */
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {IconText, Modal, PrivateNote, Tabs, Media, GridList, View, CText} from '..';
import useCheckRole from '../../hooks/useCheckRole';
import TabComments from './TabComments';
import ComposedItems from './ComposedItems';
import TabGroupItems from './TabGroupItems';
import TabCollectors from './TabCollectors';
import TabLinkedItems from './TabLinkedItems';
import { useTranslation } from 'react-i18next';
import useItemHelper from '../../hooks/useItemHelper';
import useCustomNavigate from '../../hooks/useCustomNavigate';

const ItemSheet = ({entity, setEntity, collectionEntity, setCollectionEntity, isComposition, extraComponent}) => {
  const { t } = useTranslation();

  const itemHelper = useItemHelper(entity, setEntity, collectionEntity, setCollectionEntity);

  // SELECTOR
  const auth = useSelector(state => {
    return state.auth;
  });

  // STATE
  const [noteModalVisible, setNoteModalVisible] = useState(false);

  // Navigate
  const navigate = useCustomNavigate();

  // Roles
  const checkRole = useCheckRole();

  // Api

  // Add composition
  const gotToItemAddComposition = (id) => {
    navigate('/item-modal/item-header-edit/' + id + '/composition');
  };

  // Go details
  const gotToItemFormDetails = (id) => {
    navigate('/item-modal/edit-details/item/' + id);
  };

  return (
    <View className='container pd-0'>
      <View className="row row-sm">
        <View className="col-lg-4">
          <View className="card bd-0" style={{minHeight: '250px'}}>
            {(entity?.cover?.contentUrl || entity?.parent?.cover?.contentUrl) && (
              <Media 
                mediaUri={entity?.parent?.cover?.contentUrl ?? entity?.cover?.contentUrl} 
                mode="cover"
                viewer={true}
              />
            )}
            {entity?.parent?.cover?.contentUrl && (
              <View className="pos-absolute t-0 b-0 r-0 l-0 bg-black-8">
                <View className="pos-absolute bd bd-5 b-5 r-5 ht-250" style={{minWidth: '150px'}}>
                  <Media mediaUri={entity?.cover?.contentUrl} mode="cover" viewer={true} />
                </View>
              </View>
            )}
          </View>

          {(entity?.composed || entity?.parent) && 
            <ComposedItems entity={entity} />
          }

          {entity &&
            <GridList
              data={entity?.files}
              numColumns={"col-3 col-md-3 col-lg-4 mg-b-10 ht-150"}
              className={'mg-t-30'}
              renderItem={({item, index}) =>
                <Media key={item.id} mediaUri={item?.contentUrl} mode="contain" viewer={true} />
              }
            />
          }
        </View>
        <View className="col-lg-8 mg-t-20 mg-lg-t-0">
          <View className="row row-sm">
            <View className="col-12">
              <View className="row">
                <View className="col-12">
                  <View className="tx-12 pos-absolute t-2 r-15">
                    <View className="square-10 mg-r-5 rounded-circle" style={{backgroundColor: entity?.status?.color ?? '#ffffff'}}></View>
                  </View>
                  <View className="bg-br-primary rounded ht-100p">
                    <View className="pd-25">
                      {!collectionEntity && !isComposition &&
                        entity?.quantityInUserCollection > 0 && (
                        <CText style={{marginTop: 10,
                          color: 'orange',
                          textAlign: 'left',
                          flex: 1,
                          fontSize: 13,
                          fontStyle: 'italic'
                        }}>
                          {t('fe.itemDetails.quantity.in.collection.text', {quantity: entity.quantityInUserCollection})}
                        </CText>
                      )}

                      <h3 className="tx-white-8">{entity?.name}</h3>
                      {entity?.subName && (
                        <h5 style={{color: '#fff', fontSize: 13}}>
                          {entity.subName}
                        </h5>
                      )}
                      {entity?.number && (
                        <h5 style={{color: '#f49917', fontSize: 16}}>
                          {entity?.number?.reference} {entity?.numberText}
                        </h5>
                      )}
                      {entity?.xContainer?.container?.translatedName && (
                        <CText className="d-flex" style={{color: '#6c757d', fontSize: 14}}>
                          {entity?.xContainer?.container?.translatedName}
                        </CText>
                      )}
                      {entity?.fullBoxName && (
                        <CText className="d-flex" style={{color: '#347127', fontSize: 14}}>
                          {entity?.fullBoxName}
                        </CText>
                      )}

                      <View className="d-flex justify-content-center mg-t-20">
                        {!collectionEntity && !isComposition && (
                          <IconText
                            icon={'fa fa-plus'}
                            text={t('fe.itemDetails.icon.addCollection.label')}
                            iconSize={50}
                            iconStyle={{marginTop: 5}}
                            textStyle={{color: 'rgba(127, 220, 103, 1)', fontSize: 13}}
                            onPress={() =>
                              !auth.isLoggedIn
                                ? itemHelper.goToAuhentication()
                                : itemHelper.collectionAddItemHandler(entity.uuid)
                            }
                          />
                        )}

                        {!collectionEntity && checkRole.isGranted('ROLE_ADMIN') && (
                          <IconText
                            icon={'fa fa-pen'}
                            text={t('fe.itemDetails.icon.updateDetails.label')}
                            iconSize={50}
                            iconStyle={{marginTop: 5}}
                            textStyle={{color: 'rgba(255, 193, 7, 1)', fontSize: 13}}
                            onPress={() =>
                              !auth.isLoggedIn
                                ? itemHelper.goToAuhentication()
                                : gotToItemFormDetails(entity.uuid)
                            }
                          />
                        )}

                        {/* TODO */}
                        {1 == 2 && !collectionEntity && !checkRole.isGranted('ROLE_ADMIN') && (
                          <IconText
                            icon={'fa fa-pen'}
                            text={t('fe.itemDetails.icon.reportError.label')}
                            iconSize={50}
                            iconStyle={{marginTop: 5}}
                            textStyle={{color: 'rgba(255, 193, 7, 1)', fontSize: 13}}
                            onPress={() =>
                              !auth.isLoggedIn
                                ? itemHelper.goToAuhentication()
                                : alert('Under construction')
                            }
                          />
                        )}

                        {collectionEntity && !isComposition && (
                          <IconText
                            icon={'fa fa-key'}
                            text={t('fe.itemDetails.icon.privateNotes.label')}
                            iconSize={50}
                            iconStyle={{marginTop: 5}}
                            textStyle={{color: 'rgba(199, 43, 98, 1)', fontSize: 13}}
                            onPress={() =>
                              !auth.isLoggedIn
                                ? itemHelper.goToAuhentication()
                                : setNoteModalVisible(true)
                            }
                          />
                        )}

                        {collectionEntity && !isComposition && (
                          <IconText
                            icon={'fa fa-trash'}
                            text={t('fe.itemDetails.icon.remove.label')}
                            iconSize={50}
                            iconStyle={{marginTop: 5}}
                            textStyle={{color: 'rgba(214, 61, 57, 1)', fontSize: 13}}
                            onPress={() =>
                              itemHelper.collectionDeleteItemHandler(collectionEntity?.uuid)
                            }
                          />
                        )}

                        {!collectionEntity && checkRole.isGranted('ROLE_ADMIN') && !isComposition && (
                          <IconText
                            icon={'fa fa-layer-group'}
                            text={t('fe.itemDetails.icon.addComposition.label')}
                            iconSize={50}
                            iconStyle={{marginTop: 5}}
                            textStyle={{color: 'green', fontSize: 13}}
                            onPress={() =>
                              !auth.isLoggedIn
                                ? itemHelper.goToAuhentication()
                                : gotToItemAddComposition(entity.uuid)
                            }
                          />
                        )}
                      </View>

                      {collectionEntity && !isComposition && (
                        <View>
                          <h4 className="tx-pink mg-t-30 bd-b">
                            {t('fe.itemDetails.privateNotes.title')}
                          </h4>
                          <View>
                            <View>
                              <CText className="tx-14 tx-bold tx-white-8">{t('fe.itemDetails.privateNotes.purchaseDate.label')}:</CText>
                              <CText className="tx-14 tx-bold tx-dark-8 mg-l-10">
                                {collectionEntity?.purchasedAt ? (new Date(collectionEntity?.purchasedAt)).toLocaleDateString()
                                  : 'N/A'}
                              </CText>
                            </View>
                            <View>
                              <CText className="tx-14 tx-bold tx-white-8">{t('fe.itemDetails.privateNotes.purchasePrice.label')}:</CText>
                              <CText className="tx-14 tx-bold tx-dark-8 mg-l-10">
                                {collectionEntity?.price}
                                {' '}
                                {collectionEntity?.currency?.code ||
                                  'N/A'}
                              </CText>
                            </View>
                            <View>
                              <CText className="tx-14 tx-bold tx-white-8">{t('fe.itemDetails.privateNotes.status.label')}:</CText>
                              <CText className="tx-14 tx-bold tx-dark-8 mg-l-10">
                                {collectionEntity?.status?.reference ||
                                  'N/A'}
                              </CText>
                            </View>
                            <View>
                              <CText className="tx-14 tx-bold tx-white-8">{t('fe.itemDetails.privateNotes.note.label')}:</CText>
                              <CText className="tx-14 tx-bold tx-dark-8 mg-l-10">
                                {collectionEntity?.note || 'N/A'}
                              </CText>
                            </View>
                          </View>

                          {/* Note modal */}
                          <Modal 
                            show={noteModalVisible} 
                            fullscreen={false} 
                            centered={true} 
                            handleClose={() => setNoteModalVisible(false)}
                            contentClassName={'col'}
                            className='modal-lg'
                            closeButton={true}
                          >
                            <PrivateNote collectionItem={collectionEntity} onFinish={() => setNoteModalVisible(false)} />
                          </Modal>
                        </View>
                      )}

                      {entity?.metaDescriptions?.length > 0 && (
                        <View className="mg-b-60">
                          <h4 className="tx-info tx-light mg-t-30 bd-b">
                            {t('fe.itemDetails.moreDetails.title')}
                          </h4>
                          <View className="mg-t-10">
                            {entity?.metaDescriptions.map((meta, index) => {
                              return (
                                <View key={index} className="mg-b-1">
                                  <CText className="tx-14 tx-bold tx-white-8">{meta.label}:</CText>
                                  <CText className="tx-14 tx-bold tx-dark-8 mg-l-10">{meta.content}</CText>
                                </View>
                              );
                            })}
                          </View>
                        </View>
                      )}

                      {/* licence */}
                      {entity?.itemGroup?.aliasName && (
                        <View className="tx-italic tx-secondary tx-12 mg-b-0 mg-t-40">
                          {t('fe.itemDetails.license.label')}: 
                          <CText className="tx-danger">
                            {entity?.itemGroup?.aliasName}
                          </CText>
                        </View>
                      )}
                    </View>
                  </View>
                </View>
              </View>

              {entity && (
                <View className="row">
                  <View className="col-12">
                    <Tabs>
                      <View tabLabel={<i className={"fa fa-comment tx-24"}></i>} tabDisplay={true}>
                        <TabComments item={entity} />
                      </View>

                      <View tabLabel={<i className={"fa fa-link tx-24"}></i>}>
                        {({ display }) => {
                          return <TabLinkedItems entity={entity} onDataFetch={() => display(true)} />
                        }}
                      </View>
                      
                      <View tabLabel={<i className={"fa fa-cube tx-24"}><CText className='pos-absolute mg-l-2 tx-12'>4</CText></i>}>
                        {({ display }) => {
                          return <TabGroupItems entity={entity} groupIndex={4} onDataFetch={() => display(true)} />
                        }}
                      </View>

                      <View tabLabel={<i className={"fa fa-cube tx-24"}><CText className='pos-absolute mg-l-2 tx-12'>5</CText></i>}>
                        {({ display }) => {
                          return <TabGroupItems entity={entity} groupIndex={5} onDataFetch={() => display(true)} />
                        }}
                      </View>
                      
                      <View tabLabel={<i className={"fa fa-users tx-24"}></i>}>
                        {({ display }) => {
                          return <TabCollectors entity={entity} onDataFetch={() => display(true)} />
                        }}
                      </View>
                    </Tabs>
                  </View>
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default ItemSheet;