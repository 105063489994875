/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {CText, FormInputText, Loader, ProtectedScreen, View} from '../../components';
import { UserAPI } from '../../apis/UserAPI';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useActivity from '../../hooks/useActivity';

const Verification = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const activityHook = useActivity(false);
  const [violations, setViolations] = useState([]);

  // FORM
  const {
    control,
    getValues,
    handleSubmit,
  } = useForm({
    defaultValues: {
      code: "",
      email: location?.state?.email
    }
  });

  const onSubmit = async formData => {
    var activityRef = activityHook.start();

    const response = await UserAPI.passwordToken(formData);

    if (response?.status) {
      navigate('/security/password', {state: {
          email: formData.email,
          token: response?.payload?.token,
          isNewUser: response?.payload?.isNewUser
        },
        replace: true
      });
    }

    // Violations
    setViolations(response?.violations);
    console.log(response?.violations);

    activityHook.stop(activityRef);
  };

  return (
    activityHook.isLoading ? <Loader /> :
    <ProtectedScreen condition={getValues().email}>
      <div className="br-pagebody">
        <div className="row no-gutters" /* flex-row-reverse */>
          <div className="offset-md-3 col-md-6 bg-br-primary d-flex align-items-center justify-content-center">
            <form>
              <div className="login-wrapper wd-250 wd-xl-350 mg-y-30">
                <CText className="tx-center mg-b-40 tx-warning">
                  {t('fe.authentication.verification.code.sent.message', {
                    email: getValues().email,
                    codeLength: 8
                  })}
                </CText>
                {violations?.error?.message &&
                  <View className='d-flex justify-content-center mg-t-20'>
                    <CText className='text-danger'>
                      {violations?.error?.message}
                    </CText>
                  </View>
                }
                <div className="form-group mg-t-10">
                  <Controller
                    name="code"
                    control={control}
                    render={({ field }) => (
                      <FormInputText
                        {...field}
                        className="form-control form-control-dark"
                        placeholder={t('fe.authentication.verification.form.code.placeholder')}
                        violation={violations?.code}
                      />
                    )}
                  />
                </div>
                <button
                  type="submit" 
                  className="btn btn-info btn-block"
                  onClick={handleSubmit(onSubmit)}>
                    {t('fe.authentication.verification.code.button')}
                </button>
                <div className="mg-t-30 tx-center">
                  <Link to={'/security/sign-in'} className="tx-info">
                    {t('fe.authentication.already.member.question')}
                  </Link>
                </div>
              </div>
            </form>
          </div>
          {/*
          <div className="col-md-6 bg-primary d-flex align-items-center justify-content-center">
            <div className="wd-250 wd-xl-450 mg-y-30">
              <p className="tx-white-6">{t('fe.authentication.welcome.description')}</p>
            </div> 
          </div>
          */}
        </div>
      </div>
    </ProtectedScreen>
  )
}

export default Verification;