import { useTranslation } from "react-i18next";

const NotFound = () => {
  const {t} = useTranslation();

  return (
    <div className="container">
      <div className="row">
        <div className="col pd-0 mg-t-20">
          <div className="ht-100v bd d-flex align-items-center justify-content-center">
            <div className="wd-lg-70p wd-xl-50p tx-center pd-x-40">
              <h1 className="tx-100 tx-xs-140 tx-normal tx-inverse mg-b-0">404!</h1>
              <h5 className="tx-xs-24 tx-normal tx-info mg-b-30 lh-5">{t('fe.notFound.title')}</h5>
              <p className="tx-16 mg-b-30">{t('fe.notFound.description')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound;