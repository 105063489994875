/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useRef} from 'react';
import {Media} from '..';
import { FileAPI } from '../../apis/FileAPI';

const FormInputMedia = React.forwardRef(({
  name,
  items,
  containerStyle,
  inputStyle = {},
  value,
  onChange,
  onBlur,
}, ref) => {
  const [datas, setDatas] = useState([]);

  // New files
  const hiddenFileInput = useRef();
  const hiddenAdminFileInput = useRef();
  const [newFilesPending, setNewFilesPending] = useState([]);

  // cover
  const [cover, setCover] = useState();

  // Remove file
  const removeFile = async file => {
    // If removed file is cover, unset cover
    if (file?.main) {
      setCover(null);
    }

    // And remove it from datas
    let newDatas = datas.filter(data => {
      return data.path !== file.path;
    });

    // Set new datas
    setDatas(newDatas);
  };

  // Change cover
  const handleCover = (file) => {
    setDatas((prevDatas) => {
      const newDatas = [...prevDatas];

      // Pass all images main to false
      for (var index in newDatas) {
        newDatas[index]['main'] = false;
      }

      // Get selected image index
      let updatedIndex = newDatas.findIndex((newData) => {
        return newData.path === file.path;
      });

      // Once index found, set main to true
      if (updatedIndex !== -1) {
        newDatas[updatedIndex]['main'] = true;

        // And then update cover
        setCover(newDatas[updatedIndex]);
      }

      // Finally, set datas with new properties values
      return newDatas
    });
  };

  const handleFileInputClick = event => {
    hiddenFileInput.current.click();
  };

  const handleAdminFileInputClick = event => {
    hiddenAdminFileInput.current.click();
  };

  const createFile = async (data, prefix) => {
    setNewFilesPending([...newFilesPending, prefix]);

    const response = await FileAPI.create(data);

    if (response?.status) {
      setDatas([...datas, response?.payload]);
    }

    let pendings = [...newFilesPending].filter(pending => {
      return pending !== prefix;
    });

    setNewFilesPending(pendings);
  };

  const chooseFile = (event) => {
    const {target} = event;
    const {files} = target;

    if (files && files[0]) {
      const formData = new FormData();

      formData.append("file", files[0]);

      if (hiddenAdminFileInput?.current?.contains(target)) {
        formData.append("forAdmin", true);
      }
      
      createFile(formData, (new Date()).getTime());
    }
  };

  // EFFECT

  // Default value
  useEffect(() => {
    setCover(value);
  }, [value]);

  useEffect(() => {
    setDatas(items);
  }, [items]);

  // Update React-Form
  useEffect(() => {
    var formatedDatas = [];

    datas.map(data => {
      formatedDatas.push({id: data.path, main: data?.main});
    });

    onChange && onChange(formatedDatas);
  }, [datas]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card bd-0 mg-b-30 ht-100m">
          {cover?.path && (
            <Media mediaUri={cover?.contentUrl} mode="contain" viewer={true}/>
          )}
        </div>
        <div className="row">
          {datas?.map(file => {
            return (
              <div className="col-6 mg-b-10" key={file.path}>
                <figure className="overlay ht-250 bd">
                  <Media mediaUri={file?.contentUrl} mode="contain" viewer={true} />
                  <figcaption className="d-flex align-items-end justify-content-center">
                    <div className="pos-absolute b-0 img-option img-option-sm" style={{zIndex: 998}}>
                      <span className="img-option-link" onClick={() => removeFile(file)}><div><i className="fa fa-trash"></i></div></span>
                      {file?.contentOriginUrl && <a href={file.contentOriginUrl} target="_blank" rel="noreferrer"><span className="img-option-link bg-primary"><div><i className="fa fa-save"></i></div></span></a>}
                      {file?.forAdmin ?
                        <span className="img-option-link bg-danger"><div><i className="icon ion-ios-locked"></i></div></span>
                      : 
                        <span className={`img-option-link ${cover?.path === file.path ? 'bg-warning' : ''}`} onClick={() => handleCover(file)}>
                          <div>
                            <i className="icon ion-ios-star"></i>
                          </div>
                        </span>
                      }
                    </div>
                  </figcaption>
                </figure>
              </div>
            );
          })}
          
          {newFilesPending?.map((pending, index) => {
            return (
              <div className="col-6 col-sm-6 mg-b-10" key={`pending-${index}`}>
                <figure className="overlay ht-250 bd d-flex align-items-center justify-content-center">
                  Loading.....
                </figure>
              </div>
            );
          })}

          <div className="col-6 col-sm-6 mg-b-10">
            <div className="btn ht-250 bd d-flex align-items-center justify-content-center" onClick={handleFileInputClick}>
              <i className="icon ion-ios-plus tx-80"></i>
              <input ref={hiddenFileInput} type="file" accept="image/*" style={{display: "none"}} onChange={chooseFile}/>
            </div>
          </div>
          <div className="col-6 col-sm-6 mg-b-10">
            <div className="btn ht-250 bd-danger d-flex align-items-center justify-content-center" onClick={handleAdminFileInputClick}>
              <i className="icon ion-ios-locked tx-80"></i>
              <input ref={hiddenAdminFileInput} type="file" accept="image/*" style={{display: "none"}} onChange={chooseFile}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default FormInputMedia;
