import { api } from "./configs/fetchConfig";

export const FileAPI = {
    create: async data => {
        var headers = new Headers();
        headers.set('Content-Type', '');

        return await api.fetchResource('/api/files', {
            method: 'POST',
            body: data,
            headers: headers,
        });
    },
    get: async path => {
        return await api.fetchResource('/api/files/' + path, {
            method: 'GET',
        });
    }
}