/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {
  COLLECTION_UPDATE,
  COLLECTION_DELETE,
} from '../../redux/CollectionItem/collectionItem-types';
import {Loader, ItemSheet, ProtectedScreen} from '../../components';
import { CollectionAPI } from '../../apis/CollectionAPI';
import { ItemAPI } from '../../apis/ItemAPI';
import useActivity from '../../hooks/useActivity';
import { useTranslation } from 'react-i18next';

const ItemDetails = ({isCollection}) => {
  const {id} = useParams();

  const { t } = useTranslation();

  const activityHook = useActivity();

  // SELECTOR
  const auth = useSelector(state => {
    return state.auth;
  });

  const collectionItemState = useSelector(state => {
    return state.collectionItem;
  });

  const itemState = useSelector(state => {
    return state.item;
  });

  // STATE
  const [entity, setEntity] = useState();
  const [collectionEntity, setCollectionEntity] = useState();

  // Navigate 
  const navigate = useNavigate();

  // Api

  // Retrieve Collection informations
  const fetchCollectionItem = async id => {
    const activityRef = activityHook.start();

    const response = await CollectionAPI.getCollectionItem(id);

    if (response.status) {
      setCollectionEntity(response?.payload);
      setEntity(response?.payload?.item);
    }

    activityHook.stop(activityRef);
  };

  // Retrieve Item informations
  const fetchItem = async id => {
    const activityRef = activityHook.start();

    const response = await ItemAPI.get(id);

    if (response.status) {
      setEntity(response?.payload);
      setCollectionEntity();
    }

    activityHook.stop(activityRef);
  };

  // Effect

  // When is collection
  useEffect(() => {
    isCollection && fetchCollectionItem(id);
  }, [auth, id, itemState]);

  // When is not a collection
  useEffect(() => {
    !isCollection && fetchItem(id);
  }, [auth, id, itemState]);

  useEffect(() => {
    // Collection DELETE
    if (
      collectionEntity &&
      collectionItemState.action === COLLECTION_DELETE &&
      collectionItemState.collectionItem.uuid === collectionEntity.uuid
    ) {
      navigate(`/collections/${entity?.xContainer?.category?.alias?.id}/items`);
    }

    // Collection UPDATE
    if (
      collectionEntity &&
      collectionItemState.action === COLLECTION_UPDATE &&
      collectionItemState.collectionItem.uuid === collectionEntity.uuid
    ) {
      setCollectionEntity(collectionItemState.collectionItem);
    }

  }, [collectionItemState]);

  // Set category link
  const categoryLink = !isCollection ? `/browse/${entity?.xContainer?.category?.alias?.id}` : `/collections/${entity?.xContainer?.category?.alias?.id}/items`;

  return (
    activityHook.isLoading ? <Loader /> : (
    <ProtectedScreen condition={(!isCollection && entity?.uuid) || (isCollection && collectionEntity?.uuid)}>
      <div className='container mg-b-60'>
        <div className='row'>
          <div className='col'>
            <div className="br-pagetitle mg-t-20-force pd-x-15 bg-black-1">
              <i className={`icon ${entity?.xContainer?.category?.icon ? entity?.xContainer?.category.icon : 'ion-earth'}`}></i>                            
              <div>
                <h4>{entity?.fullName}</h4>
                <p className="mg-b-0">
                  {isCollection && (
                    <>
                      <Link
                        className="tx-white-6"
                        to={{
                          pathname: '/collections',
                        }}>
                        <span className='tx-success'>Mes collections</span>
                      </Link>
                      {' - '}
                    </>
                  )}
                  {entity?.xContainer?.category?.alias?.parent?.translatedName}
                  {' - '}
                  <Link
                    to={{
                      pathname: categoryLink,
                    }}>
                    {entity?.xContainer?.category?.alias?.translatedName}
                  </Link>
                </p>
              </div>
            </div>
            <div className="br-pagebody mg-t-20-force">
              <ItemSheet 
                entity={entity} 
                setEntity={setEntity}
                isComposition={entity?.parentUuid ? true : false}
                collectionEntity={collectionEntity}
                setCollectionEntity={setCollectionEntity}
              />
            </div>
          </div>
        </div>
      </div>
    </ProtectedScreen>
  ))
}

export default ItemDetails;