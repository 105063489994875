import * as env from '../env';

const useMercure = () => {
  const MERCURE_HUB = env.MERCURE_HUB;

  var topic = (data) => {
    return new EventSource(MERCURE_HUB + '?topic=' + encodeURIComponent(data));
  };

  return {
    topic: topic,
  };
};

export default useMercure;
