/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';

const FlatList = ({data, renderItem, onEndReached, onComponentScroll, ListEmptyComponent, FooterComponent, className, horizontal = false}) => {
  const self = useRef();
  
  // Get closest scrollable parent
  const getScrollParent = (node) => {
    const isElement = node instanceof HTMLElement;
    const overflowY = isElement && window.getComputedStyle(node).overflowY;
    const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';
  
    if (!node) {
      return null;
    } else if (isScrollable && node.scrollHeight >= node.clientHeight) {
      return node;
    }
  
    return getScrollParent(node.parentNode) || document.scrollingElement;
  }

  useEffect(() => {
    // Window scroll listener
    const handleWindowScroll = event => {
      onComponentScroll && onComponentScroll(window.scrollY);

      var scrollMax = window?.scrollMaxY ? window.scrollMaxY : document.documentElement.scrollHeight - document.documentElement.clientHeight;
      var scrollEndReached = parseInt(window.scrollY) >= parseInt(scrollMax);
      
      if (scrollMax > 0 && scrollEndReached) {
        onEndReached();
      }
    };

    // Element scroll listener
    const handleElementScroll = event => {
      onComponentScroll && onComponentScroll(event.currentTarget.scrollTop);

      var scrollMax = event.currentTarget.scrollHeight;
      var scrollEndReached = (event.currentTarget.offsetHeight + event.currentTarget.scrollTop) === scrollMax;

      if (scrollMax > 0 && scrollEndReached) {
        onEndReached();
      }
    };

    // If closest scrollable parent 
    var closestScrollableParent = getScrollParent(self.current);
    closestScrollableParent.addEventListener('scroll', handleElementScroll);
    
    // Default scrollable window
    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
      closestScrollableParent.addEventListener('scroll', handleElementScroll);
    };
  }, []);

  const verticalDisplay = () => {
    return (
      <div ref={self} className={className}>
        {data?.map((item, index) => {
          return item && renderItem({item, index});
        })}
        {ListEmptyComponent && data?.length === 0 && ListEmptyComponent()}
        {FooterComponent && FooterComponent()}
      </div>
    )
  }

  const horizontalDisplay = () => {
    return (
      <div className={className} style={{maxWidth: '100%', whiteSpace: 'nowrap', overflowX: 'auto'}}>
        {data?.map((item, index) => {
          return item && (<div key={index} style={{display: 'inline-block'}}>{renderItem(item, index)}</div>);
        })}
        {ListEmptyComponent && data?.length === 0 && ListEmptyComponent()}
        {FooterComponent && FooterComponent()}
      </div>
    )
  }

  return (
    horizontal ? horizontalDisplay(): verticalDisplay()
  )
}

export default FlatList;