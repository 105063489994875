/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import { CategoryTreeLink, Loader } from '../../components';
import { CategoryAPI } from '../../apis/CategoryAPI';
import useActiveLink from '../../hooks/useActiveLink';

const AdminForms = () => {
  // SET ACTIVELINK
  useActiveLink('adminLayout', 'forms');

  const [activity, setActivity] = useState(true);
  const [categories, setCategories] = useState();
  
  const fetchCategory = async () => {
    setActivity(true);

    const response = await CategoryAPI.getAllProtected();

    if (response.status) {
      setCategories(response?.payload);
    }

    setActivity(false);
  };

  // Load categories
  useEffect(() => {
    fetchCategory();
  }, []);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col'>
          <section className='bg-black-1'>
            {activity ? <Loader centerScreen={false} /> :
            <div className="ht-100p">
              <div className='row'>
                <div className='col-12 mg-b-10'>
                  <div className="br-pagetitle pd-x-15 bg-black-1">
                    <i className="icon ion-ios-search"></i>
                    <div>
                      <h4>Forms</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='bg-black-1 pd-20'>
                    <div className="row">
                      <div className="col-12">
                        <CategoryTreeLink data={categories} to={'/administration/forms/%id%/container/settings'}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          </section>
        </div>
      </div>
    </div>
  )
}

export default AdminForms;