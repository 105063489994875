/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useDebounce} from 'use-debounce';
import {Modal, Media, ItemThumb, ScrollList, Loader} from '..';
import { ItemAPI } from '../../apis/ItemAPI';

const FormInputObject = React.forwardRef(({
  name,
  items,
  itemsFromUrl,
  containerStyle,
  inputStyle = {},
  value,
  onChange,
  onBlur,
}, ref) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [search, setSearch] = useState(null);
  const [searchDebounced] = useDebounce(search, 1000);
  const [datas, setDatas] = useState([]);

  const listEmpty = () => {
    return (
      <div className="col-12">
        <span>No data found</span>
      </div>
    );
  };

  // Set default data
  const setDefaultValue = () => {
    if (value && value.length > 0) {
      setDatas(value);
    }
  };

  // Add data
  const addData = item => {
    let dataFound = datas.find(data => {
      return data.uuid === item.uuid;
    });

    if (!dataFound) {
      setDatas([...datas, item]);
    }

    setModalVisible(false);
  };

  // Remove data
  const removeData = async item => {
    let newDatas = datas.filter(data => {
      return data.uuid !== item.uuid;
    });

    setDatas(newDatas);
  };

  const updateSearch = text => {
    setSearch(text);
  };

  // EFFECT

  // Default value
  useEffect(() => {
    setDefaultValue();
  }, [value]);

  // Update React-Form
  useEffect(() => {
    var formatedDatas = [];

    datas.map((data) => {
      formatedDatas.push({id: data.uuid});
    });

    onChange && onChange(formatedDatas);
  }, [datas]);

  return (
    <div className="row">
      {datas?.map((data) => {
        return (
          <div className="col-3 col-sm-3" key={data.uuid}>
            <div className="text-right tx-danger tx-bold" onClick={() => removeData(data)}>remove</div>
            <ItemThumb data={data} />
          </div>
        );
      })}
      <div className="col-3 col-sm-3">
        <div className="mg-t-20"></div>
        <div className="btn ht-250 bd d-flex align-items-center justify-content-center" onClick={() => setModalVisible(true)}>
          <i className="icon ion-ios-plus tx-80"></i>
        </div>
      </div>

      <Modal show={modalVisible} handleClose={() => setModalVisible(false)}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 pd-y-20 bg-dark tx-white">
              <div className="row">
                <div className="col-12 mg-b-20">
                  <div className="form-layout form-layout-3">
                    <div className="row no-gutters">
                      <div className="col-12">
                        <div className="form-group">
                          <div className="input-group transition">
                            <span className="input-group-btn mg-r-60">
                              <i className="fa fa-search pos-absolute l-5 t-0" style={{fontSize: 40}}></i>
                            </span>
                            <input 
                              className="form-control" 
                              type="text" 
                              name="q" 
                              placeholder="Search..." 
                              value={search ? search : ''}
                              onChange={(e) => updateSearch(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {searchDebounced ? 
                <ScrollList
                  urlCaller={{
                    url: ItemAPI.getByCriteria,
                    params: [searchDebounced, 1],
                    paginationIndex: 1,
                  }}
                  renderItem={({item, index}) => (
                    <div className="col-12 bd-b mg-b-5 pd-b-5" key={item?.uuid} onClick={() => addData(item)}>
                      <div className="row">
                        <div className="col-2">
                          <Media mediaUri={item?.cover?.contentUrl} mode="contain" />
                        </div>
                        <div className="col-auto">
                          <span style={{fontSize: 11, fontWeight: 'bold'}}>
                            {item?.fullName.toString().trim()}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  numColumns={"col-12"}
                  extraData={searchDebounced ?? []}
                  height={600}
                  ListEmptyComponent={listEmpty}
                  FooterComponent={() => (
                    <div className="row">
                      <div className="col-12 tx-center">
                        <Loader centerScreen={false} />
                      </div>
                    </div>
                  )}
                /> :
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="ht-100 d-flex align-items-center justify-content-center">
                      <h6 className="tx-uppercase mg-b-0 tx-roboto tx-normal tx-spacing-2">Please enter your search terms</h6>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default FormInputObject;
