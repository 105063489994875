/* eslint-disable array-callback-return */
var Text = (function() {
  var truncate = (str, len) => {
    if (str.length > len) {
      if (len <= 3) {
        return str.slice(0, len - 3) + "...";
      }
      else {
        return str.slice(0, len) + "...";
      };
    }
    else {
      return str;
    };
  }

  var sprintf = (str, object) => {
    const regex = /%(.*?)%/g; 
    const properties = str.match(regex).map(match => match.slice(1, -1)); 

    properties?.map((property) => {
      str = str.replace("%id%", object[property]);
    });

    return str;
  }

  return {
    truncate: truncate,
    sprintf: sprintf
  }
})();

export default Text;