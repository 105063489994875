/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import { EmptyContent, Loader, NotificationLine, Paginator } from '../../components';
import { NotificationAPI } from '../../apis/NotificationAPI';
import useActivity from '../../hooks/useActivity';
import useActiveLink from '../../hooks/useActiveLink';

const NotificationHistory = () => {
  // SET ACTIVELINK
  useActiveLink('historyLayout', 'notifications');

  const [searchParams] = useSearchParams();
  const [notifications, setNotifications] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [activity, setActivity] = useState(true);

  const activityHook = useActivity();

  const auth = useSelector(state => {
    return state.auth;
  });

  const fetchAll = async (page = 1) => {
    const activityRef = activityHook.start();

    const response = await NotificationAPI.getAll([], page);

    if (response?.status) {
      setTotalItems(response?.totalItems);
      setNotifications(response?.payload);
    }

    activityHook.stop(activityRef);
  };
  
  // Load users requests
  useEffect(() => {
    fetchAll(searchParams.get('page') ?? 1);
  }, [searchParams]);

  return (
    <section className='bg-black-1'>
      {activityHook.isLoading ? <Loader centerScreen={false} /> :
        <div className="ht-100p">
          <div className='row'>
            <div className='col-12 mg-b-10'>
              <div className="br-pagetitle pd-x-15 bg-black-1">
                <i className="icon ion-ios-search"></i>
                <div>
                  <h4>Notifications</h4>
                  {!totalItems ? '' : <p className="mg-b-0">{`${totalItems} notification(s)`}</p>}
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='bg-black-1 pd-20'>
                <div className="row">
                  <div className="col-12">
                    {notifications?.length <= 0 ? (
                      <EmptyContent 
                        text={'Aucune Notification'}
                      />
                    ) : (
                      <>
                        <div className="media-list">
                          {notifications?.map((notification) => {
                            return (<NotificationLine key={notification?.uuid} data={notification} />)
                          })}
                        </div>
                        <Paginator totalItems={totalItems}/>
                      </>
                    )}
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    }
    </section>
  )
}

export default NotificationHistory;