/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {Media, NiceDropdown, ProtectedScreen} from '../components';
import { Route, Routes, useLocation, useParams } from 'react-router';
import { NotFound, Collections, CollectionItems, CollectionScans } from '.';
import { useSelector } from 'react-redux';
import { UserAPI } from '../apis/UserAPI';
import { useTranslation } from 'react-i18next';

const CollectionLayout = () => {
  const {t} = useTranslation();
  const {collectorUuid} = useParams();
  const location = useLocation();
  const [collector, setCollector] = useState();
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [menus, setMenus] = useState();

  const auth = useSelector(state => {
    return state.auth;
  });

  const fetchCollector = async uuid => {
    const response = await UserAPI.get(uuid);

    if (response?.status) {
      setCollector(response?.payload);
    }
  };

  const buildMenus = (collector, isCurrentUser) => {
    const pathnamePrefix = !isCurrentUser ? '/collector/' + collector?.uuid : '';

    const pathnames = [
      {identifier: 'collections', pathname: `${pathnamePrefix}/collections`, label: t('fe.collection.collections.menu'), enable: true},
      {identifier: 'collections-scans', pathname: `${pathnamePrefix}/collections/scans`, label: t('fe.collection.scans.menu'), enable: isCurrentUser}
    ];

    setMenus(pathnames);
  }

  useEffect(() => {
    if (auth?.user?.uuid && !collectorUuid) {
      setCollector(auth.user);
      setIsCurrentUser(true);
    }
  }, [auth]);

  useEffect(() => {
    collectorUuid && fetchCollector(collectorUuid);
  }, [])

  useEffect(() => {
    collector?.uuid && buildMenus(collector, isCurrentUser);
  }, [collector])

  return (
    <ProtectedScreen condition={collectorUuid !== auth?.user?.uuid}>
      <section className="mg-t-80">
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="card widget-7 bd bd-gray-400 ht-auto">
              <div className="tx-center">
                <div className='d-flex align-items-center justify-content-center'>
                  <Media mediaUri={collector?.avatar?.contentUrl} avatar={true} size={150} />
                </div>
                <h4 className="tx-normal tx-white tx-roboto mg-t-20 mg-b-2">{collector?.nickname}</h4>
                <p className="mg-b-20">{collector?.description}</p>
                {/*<p className="mg-b-25 tx-20">
                  <span className="tx-primary mg-r-5"><i className="fab fa-facebook-official"></i></span>
                  <span className="tx-info mg-r-5"><i className="fab fa-twitter"></i></span>
                  <span className="tx-danger"><i className="fab fa-pinterest"></i></span>
                </p>*/}
                {/*
                <p className="mg-b-0">
                  <span className="btn btn-secondary pd-x-50">{t('fe.collection.collector.follow.button')}</span>
                </p>
              */}
              </div>
            </div>
            <div className="mg-y-10">
              {menus && <NiceDropdown data={menus} scope={'collectionLayout'}/>}
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <Routes location={location?.state?.backgroundLocation || location}>
              <Route index path="/" element={<Collections collector={collector} isCurrentUser={isCurrentUser}/>} />
              <Route path=":id/items" element={<CollectionItems collector={collector} isCurrentUser={isCurrentUser}/>} />
              <Route index path="scans" element={<CollectionScans />} />
              <Route path="collections" element={<Collections collector={collector} isCurrentUser={isCurrentUser} />} />
              <Route path="collections/:id/items" element={<CollectionItems collector={collector} isCurrentUser={isCurrentUser} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </section>
    </ProtectedScreen>
  );
}

export default CollectionLayout;
