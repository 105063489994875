/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import logo from '../crosslud.png'
import {CText, LangSwitcher, Menu, Link, NotificationBell, UserMenu} from '../components';
import useCheckRole from '../hooks/useCheckRole';
import {ProtectedRoute} from '../components';
import {
  NotFound,
  Home,
  Items, 
  RequestItem,
  Search, 
  SignIn, 
  SignUp,
  Verification,
  Password,
  ResetPasswordRequest,
  Logout,
  AccountLayout,
  HistoryLayout,
  CollectionLayout,
} from '.';
import { useTranslation } from 'react-i18next';

const MainLayout = () => {
  const location = useLocation();
  const {t} = useTranslation();
  
  const auth = useSelector(state => {
    return state.auth;
  });

  const checkRole = useCheckRole();

  return (
    <div className="wrapper">
      <header className="header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="br-header">
                <div className="br-header-left">
                  <div className="navicon-left d-lg-none">
                    <a id="btnLeftMenuMobile" href="">
                      <i className="icon ion-navicon-round"></i>
                    </a>
                  </div>
                  <div className="input-group d-none d-sm-inline-flex- wd-170 transition">
                    <span className="input-group-btn">
                        <button className="btn btn-secondary" type="button"><i className="fas fa-search"></i></button>
                    </span>
                  </div>    
                  <div className="bg-black-2 pd-x-20 align-items-center justify-content-start d-none d-lg-flex">
                    <Link to={'/'}>
                      <h4 className="mg-b-0 tx-uppercase tx-bold tx-spacing--2 tx-white mg-r-20 tx-poppins">
                        <img src={logo} className="ht-50" alt={logo} />
                      </h4>
                    </Link>
                    <Menu />
                  </div>
                </div>
                <div className="br-header-right">
                  <nav className="nav">
                    <Link className="nav-link pd-x-7 pos-relative" to={'/search'}>
                      <i className="icon ion-ios-search tx-24"></i>
                    </Link>
                    {checkRole.isGranted('ROLE_ADMIN') &&
                      <Link className="nav-link pd-x-7 pos-relative" to="/request-item">
                        <i className="icon ion-ios-plus tx-24"></i>
                      </Link>
                    }
                    {!auth.isLoggedIn ? (
                      <Link className="nav-link pd-x-7 mg-r-15 pos-relative" to={'/security/sign-in'}>
                        <i className="icon ion-ios-person tx-24"></i>
                      </Link>
                    ): 
                      <>
                        <NotificationBell />
                        <UserMenu user={auth?.user} />
                      </>
                    }
                  </nav>
                  {/*
                  <div className="navicon-right">
                    <LangSwitcher />
                  </div>
                  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <main className="br-mainpanel">
              <Routes location={location?.state?.backgroundLocation || location}>
                {/* Simple routes */}
                <Route index path="/" element={<Home />} />
                <Route path="/browse/:id" element={<Items />} />
                <Route path="/search" element={<Search />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/collector/:collectorUuid/*" element={<CollectionLayout />} />

                {/* Protected routes for ROLE_USER */}
                <Route path="" element={<ProtectedRoute role={'ROLE_USER'} />}>
                  {/* AccountLayout */}
                  <Route path="/account/*" element={<AccountLayout />} />
                  {/* CollectionLayout */}
                  <Route path="/collections/*" element={<CollectionLayout />} />
                  {/* HistoryLayout */}
                  <Route path="/history/*" element={<HistoryLayout />}/>
                </Route>

                {/* Protected routes for ROLE_ADMIN */}
                <Route path="" element={<ProtectedRoute role={'ROLE_ADMIN'} />}>
                  <Route path="/request-item" element={<RequestItem />} />
                  <Route path="/request-assignations" element={<RequestItem />} />
                  <Route path="/request-assignations/:id" element={<RequestItem />} />
                </Route>
                
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainLayout;
