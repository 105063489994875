/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {ModalLink, ItemCard, GridList} from '..';
import { ItemAPI } from '../../apis/ItemAPI';
import TabGroupItemsMore from './TabGroupItemsMore';
import { useTranslation } from 'react-i18next';

const TabGroupItems = ({entity, groupIndex, onDataFetch}) => {
  const { t } = useTranslation();
  const [items, setItems] = useState();
  const [totalItems, setTotalItems] = useState();
  const [paginator, setPaginator] = useState();

  // API
  const fetchGroupItems = async () => {
    const response = await ItemAPI.getGroupItems(entity.uuid, groupIndex, [], 1);

    if (response.status) {
      setTotalItems(response?.totalItems);
      setPaginator(response?.paginator);

      setItems(response.payload);
    }
  };

  // Effect
  useEffect(() => {
    fetchGroupItems();
  }, []);

  useEffect(() => {
    items?.length > 0 && onDataFetch();
  }, [items]);
  
  return (
    <div className='container pd-0'>
      <div className='row'>
        <div className="col mg-t-40 tx-center">
          <h6>
            {t("fe.itemSheet.tabGroupItems.title").toUpperCase()}
          </h6>
        </div>
      </div>
      <div className='row'>
        <div className="col mg-t-20">
          <GridList
            data={items}
            numColumns={'col-lg-3 col-md-4 col-6'}
            renderItem={({item, index}) =>
              <div className="mb-3">
                <ModalLink
                  to={{
                    pathname: `/item-modal/item/details/${(item.parentUuid || item.uuid)}`,
                  }}>
                  <ItemCard data={item} overlay={true} />
                </ModalLink>
              </div>
            }
          />

          {paginator?.next && (
            <TabGroupItemsMore entity={entity} groupIndex={groupIndex} />
          )}
        </div>
      </div>
    </div>
  )
}

export default TabGroupItems;