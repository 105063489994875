/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Loader, Review, Modal, Comment} from '..';
import { ItemAPI } from '../../apis/ItemAPI';
import useActivity from '../../hooks/useActivity';
import Alert from '../../helpers/Alert';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const TabComments = ({item}) => {
  const { t } = useTranslation();

  // SELECTOR
  const auth = useSelector(state => {
    return state.auth;
  });

  const reviewState = useSelector(state => {
    return state.review;
  });

  const navigate = useNavigate();
  const activityHook = useActivity();

  // STATE
  const [modalVisible, setModalVisible] = useState(false);

  const [currentUserReview, setCurrentUserReview] = useState();
  const [othersUsersReviews, setOthersUsersReviews] = useState([]);

  // Api
  const fetchItemOthersUsersReviews = async id => {
    const response = await ItemAPI.getOthersUsersReviews(id);

    if (response?.status) {
      setOthersUsersReviews(response.payload);
    }
  };

  const fetchItemCurrentUserReview = async id => {
    var activityRef = activityHook.start();

    const response = await ItemAPI.getCurrentUserReview(id);

    if (response?.status) {
      setCurrentUserReview(response.payload);
    }

    activityHook.stop(activityRef);
  };

  // Go authentication
  const goToAuhentication = () => {
    Alert.alert(
      t('fe.authentication.confim.title'), 
      t('fe.authentication.confim.description'),
      [
        {
          text: t('fe.confirm.button'),
          onPress: () => {
            navigate('/sign-in');
          },
          style: 'confirm',
        },
        {
          text: t('fe.cancel.button'),
          style: 'cancel',
        },
      ],
    );
  };

  // Effect

  // Anyway retrieve LinkedItems and Reviews and others
  useEffect(() => {
    fetchItemOthersUsersReviews(item.uuid);
  }, [item]);

  // On comment edit
  useEffect(() => {
    if(auth.isLoggedIn) {
      fetchItemCurrentUserReview(item.uuid);
    } else {
      activityHook.stop(activityHook.start());
    }
  }, [auth, reviewState]);

  return (
    <div className='container pd-0'>
      <div className='row'>
        <div className="col mg-t-40 tx-center">
          <h6 className='tx-upper'>
            {t("fe.itemSheet.tabComments.title").toUpperCase()}
          </h6>
        </div>
      </div>
      <div className='row'>
        <div className="col-12 mg-t-20">
          <div className="card shadow-base widget-5 mg-b-20">
            <div className="list-group list-group-flush">
            {/* connected user item review */}
            {activityHook.isLoading ? <Loader centerScreen={false} /> : (
              <Review
                item={currentUserReview}
                defaultAvatar={auth?.user?.avatar?.contentUrl}
                defaultMessage={t('fe.itemDetails.tabComments.review.addNewReview.text')}
                footer={
                  <div onClick={() =>
                    !auth.isLoggedIn
                      ? goToAuhentication()
                      : setModalVisible(true)
                  }>
                    {currentUserReview?.id ?
                      <input className="btn btn-warning btn-sm mg-t-10 float-right" type="button" value={t('fe.itemDetails.tabComments.review.update.button')} />
                      :
                      <input className="btn btn-primary btn-sm mg-t-10 float-right" type="button" value={t('fe.itemDetails.tabComments.review.add.button')} />
                    }
                  </div>
                }
              />
            )}
            </div>
          </div>

          {/* others users reviews */}
          {othersUsersReviews?.length > 0 && (
            <div className="card shadow-base widget-5">
              <div className="list-group list-group-flush">
                {othersUsersReviews.map(othersUsersReview => {
                  return (
                    <Review
                      item={othersUsersReview}
                      key={othersUsersReview.id}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>

        {/* Comment modal */}
        <Modal 
          show={modalVisible} 
          fullscreen={false} 
          centered={true} 
          handleClose={() => setModalVisible(false)}
          contentClassName={'col'}
          closeButton={true}
        >
          <Comment item={item} onFinish={(newReview) => {
            setModalVisible(false);
          }} />
        </Modal>
      </div>
    </div>
  )
}

export default TabComments;