import { api } from "./configs/fetchConfig";
import {fetchUtils} from './configs/fecthUtils';
import { commonSearchSchema } from "./configs/schema";

export const CommonAPI = {
  login: async (email = '', password = '') => {
    return await api.getToken(email, password);
  },
  logout: async() => {
    return await api.unsetToken();
  },
  getInfoFeeds: async () => {
    return await api.fetchResource('/api/info-feeds', {
      method: 'GET',
    });
  },
  getInfoFeed: async (id) => {
    return await api.fetchResource('/api/info-feeds/' + id);
  },
  getCompanies: async (filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
      
    let q = '?pagination=true&page=' + page + (query && '&' + query);
      
    return await api.fetchResource('/api/item-companies' + q, {
      method: 'GET',
    });
  },
  getSupports: async (filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
      
    let q = '?pagination=true&page=' + page + (query && '&' + query);

    return await api.fetchResource('/api/supports' + q, {
      method: 'GET',
    });
  },
  getSupportCodes: async (supportId, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
      
    let q = '?page=' + page + (query && '&' + query);

    return await api.fetchResource('/api/supports/' + supportId + '/item-support-codes' + q, {
      method: 'GET',
    });
  },
  getCurrencies: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    return await api.fetchResource('/api/currencies?' + newFilters.join('&'), {
      method: 'GET',
    });
  },
  getItemStatuses: async (filters = [], page = 1) => {
    let q = '?page=' + page + '&' + filters.join('&');

    var url = '/api/item-statuses' + q;

    return await api.fetchResource(url, {
      method: 'GET',
    });
  },
  getProtectAdminUsers: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    return await api.fetchResource('/api/protected-admin-users?' + newFilters.join('&'), {
      method: 'GET',
    });
  },
  getCivilities: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);
    
    return await api.fetchResource('/api/civilities?' + newFilters.join('&'), {
      method: 'GET',
    });
  },
  getCountries: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);
    
    return await api.fetchResource('/api/countries?' + newFilters.join('&'), {
      method: 'GET',
    });
  },
  getTranslations: async (locale, domain = null) => {
    return await api.fetchResource(`/api/i18n/${locale}${domain ? '/' + domain : ''}`, {
      method: 'GET',
    });
  },
}
