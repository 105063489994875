/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { NotificationAPI } from '../../../apis/NotificationAPI';
import { useDispatch } from 'react-redux';
import { notificationUpdate } from '../../../redux/Notification/notification-actions';

const NotificationDetails = ({notification, onFinish}) => {
  const dispatch = useDispatch();

  const updateOpened = async (id) => {
    const response = await NotificationAPI.update(id, {opened: true});

    if (response?.status) {
      dispatch(notificationUpdate(response?.payload));
    }
  }

  useEffect(() => {
    !notification?.opened && updateOpened(notification?.uuid);
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 pd-x-40 pd-b-40">
          <div className="row">
            <div className="col-12 mg-b-20">
              <h4 className="text-center">{notification?.translatedTitle}</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {notification?.translatedContent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationDetails;
