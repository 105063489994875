import React, {useEffect} from 'react';
import {Routes, Route, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {CText, LangSwitcher, Link, ProtectedRoute, Splash} from './components';
import {
  ModalLayout,
  AdminLayout,
  MainLayout,
  RequestItemCode, 
  ItemDetails,
  ItemHeaderEdit,
  ItemDetailsEdit,
  About,
  TermsOfUse,
  PrivacyPolicy,
} from './screens';
import logo from './crosslud.png';
import {userLogin, userNotAuthenticated} from './redux/Auth/auth-actions';
import { UserAPI } from './apis/UserAPI';
import useActivity from './hooks/useActivity';
import { useTranslation } from 'react-i18next';
import SecurityLayout from './screens/SecurityLayout';

const App = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const activityHook = useActivity();

  const auth = useSelector(state => {
    return state.auth;
  });

  const location = useLocation();
  
  // On load try to reAuthenticate user
  // This code block is placed here because App
  // is used in all application like a main layout
  const userSessionHolder = async () => {
    var activityRef = activityHook.start();

    const response = await UserAPI.getInfo();

    if (response?.status) {
      dispatch(userLogin({user: response?.payload}));
    } else {
      dispatch(userNotAuthenticated());
    }

    activityHook.stop(activityRef);
  };
  
  useEffect(() => {
    userSessionHolder();
  }, []);

  return (
    activityHook.isLoading
    || auth.isLoggedIn === undefined
    ? <Splash /> : (
      <div className="App">
        <Routes location={location?.state?.backgroundLocation || location}>
          {/* AdminLayout */}
          <Route path="administration" element={<ProtectedRoute role={'ROLE_ADMIN'} />}>
            <Route path="*" element={<AdminLayout />}/>
          </Route>

          {/* MainLayout */}
          <Route path="*" element={<MainLayout />} />
          
          {/* SecurityLayout */}
          <Route path="security/*" element={<SecurityLayout />} />
        </Routes>

        {/* ModalLayout */}
        <Routes>
          <Route path="item-modal" element={<ModalLayout />}>
            <Route path="item/details/:id" element={<ItemDetails />} />
            <Route path="collection-item/details/:id" element={<ItemDetails isCollection={true} />} />
            <Route path="*" element={<ProtectedRoute role={'ROLE_ADMIN'} />}>
              <Route path="item-header-edit" element={<ItemHeaderEdit />} />
              <Route path="item-header-edit/:id" element={<ItemHeaderEdit />} />
              <Route path="item-header-edit/:id/composition" element={<ItemHeaderEdit isComposition={true}/>} />
              <Route path="request-item/:code" element={<RequestItemCode />} />
              <Route path="edit-details/item/:id" element={<ItemDetailsEdit />} />
            </Route>
          </Route>
          <Route path="modal" element={<ModalLayout />}>
            <Route path="about" element={<About />} />
            <Route path="terms-of-use" element={<TermsOfUse />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="contact-us" element={<ItemDetails />} />
          </Route>
        </Routes>
        <div className='bg-black-3 mg-t-30'>
          <div className="pd-y-20">
            <div className="container">
              <div className="row">
                <div className="col-lg-9 col-md-6 col-sm-6">
                  <div className='d-flex'>
                    <div className="tx-center">
                      <Link to={"/"}>
                        <img src={logo} style={{height: '40px'}} alt="v2ay's Header Logo" />
                      </Link>
                      <p className='mg-t-10'><CText>Database Collection.</CText></p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className='mg-b-20'>
                    <LangSwitcher />
                  </div>
                  <ul className="list-group">
                    {[].map((i) => {
                      return (
                        <li key={i} className="list-group-item bd-0 pd-0 pd-y-5">
                          <p className="mg-b-0">
                            <strong className="tx-white tx-medium">Retina: 13.3-inch</strong>
                          </p>
                        </li>
                      )
                    })}
                    {/*
                    <li className="list-group-item bd-0 pd-0 pd-y-5">
                      <Link to={"/modal/about"}><strong className="tx-medium">{t('fe.about.link')}</strong></Link>
                    </li>
                    */}
                    <li className="list-group-item bd-0 pd-0 pd-y-5">
                      <Link to={"/modal/terms-of-use"}><strong className="tx-medium">{t('fe.terms.of.use.link')}</strong></Link>
                    </li>
                    <li className="list-group-item bd-0 pd-0 pd-y-5">
                      <Link to={"/modal/privacy-policy"}><strong className="tx-medium">{t('fe.privacy.policy.link')}</strong></Link>
                    </li>
                  </ul>
                  <h4 className='mg-t-30'><strong>{t('fe.contact.us.text')}</strong></h4>
                  E-mail: <span className='tx-info'>contact@crosslud.com</span>
                </div>
              </div>
            </div>
          </div>
          <div className="bd-t">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <footer className="br-footer">
                    <div className="footer-left">
                      <div className="mg-b-2">Copyright © 2017 <Link to={"/"}><span className='tx-info'>www.crosslud.com</span></Link>. {t('fe.all.rights.reserved.text')}</div>
                    </div>
                    <div className="footer-right d-flex align-items-center">
                      <span className="tx-uppercase mg-r-10">{t('fe.share.text')}:</span>
                      <a rel="noreferrer" target="_blank" className="pd-x-5" href="https://www.instagram.com/_crosslud_"><i className="fab fa-instagram tx-20"></i></a>
                    </div>
                  </footer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default App;
