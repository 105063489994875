/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {useParams, Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {checkerCancel} from '../../redux/Checker/checker-actions';
import {
  COLLECTION_DELETE,
  COLLECTION_BATCH_DELETE,
} from '../../redux/CollectionItem/collectionItem-types';
import {ItemCard, ScrollList, ScrollListHeader, ScrollListCheckerHeader, SearchFilter, SearchOrder, CheckWrapper, ModalLink, ProtectedScreen, Loader, View, EmptyContent} from '../../components';
import useSearchFilter from '../../hooks/useSearchFilter';
import FormData from '../../helpers/FormData';
import { CategoryAPI } from '../../apis/CategoryAPI';
import { CollectionAPI } from '../../apis/CollectionAPI';
import useActivity from '../../hooks/useActivity';
import useActiveLink from '../../hooks/useActiveLink';
import { useTranslation } from 'react-i18next';

const CollectionItems = ({collector, isCurrentUser}) => {
  const {t} = useTranslation();

  // PARAMS
  const {id} = useParams();
  const checkerControlName = `collection-${id}`;
  
  // SET ACTIVELINK
  useActiveLink('collectionLayout', 'collections');

  // DISPACTH
  const dispatch = useDispatch();

  // SELECTOR
  const collectionItemState = useSelector(state => {
    return state.collectionItem;
  });

  const checkerState = useSelector(state => {
    return state.checker;
  });

  // HOOK
  const searchFilterHook = useSearchFilter(id, 'collection');
  const activityHook = useActivity();

  // STATE
  const [category, setCategory] = useState();
  const [filterFields, setFilterFields] = useState();
  const [sortFields, setSortFields] = useState();
  const [showInfoBar, setShowInfoBar] = useState(false);

  // ACTION & API
  const fetchCategory = async (id) => {
    var activityRef = activityHook.start();

    const response = await CategoryAPI.get(id);

    if (response.status) {
      setCategory(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  // Filter fields
  const fetchCategorySearchFormFields = async categoryId => {
    var activityRef = activityHook.start();

    const response = await CategoryAPI.getSearchFormFields(categoryId, 1);

    if (response.status) {
      setFilterFields(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  // Sort fields
  const fetchCategorySearchSortFields = async categoryId => {
    var activityRef = activityHook.start();

    const response = await CategoryAPI.getSearchSortFields(categoryId, 1);

    if (response.status) {
      setSortFields(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  const onScroll = (event) => {
    const {drt, scrollTop} = event;

    if (drt === 'top' && scrollTop > 250) {
      setShowInfoBar(true);
    } else {
      setShowInfoBar(false);
    }
  };

  // Collection ScrollList state handler
  const collectionStateHandler = (datas, setDatas) => {
    // COLLECTION_DELETE_ITEM
    if (collectionItemState.action === COLLECTION_DELETE) {
      if (collectionItemState?.collectionItem?.uuid) {
        const foundData = datas.find(data => {
          return data?.uuid === collectionItemState?.collectionItem?.uuid
        });

        if (foundData) {
          const filteredData = datas.filter(
            data => data.uuid !== foundData.uuid,
          );

          setDatas(filteredData);
        }
      }
    }

    // COLLECTION_BATCH_DELETE
    if (collectionItemState.action === COLLECTION_BATCH_DELETE) {
      var filteredData = [...datas];

      // eslint-disable-next-line array-callback-return
      checkerState?.data[checkerControlName]?.checkList.map(checked => {
        filteredData = filteredData.filter(
          data => data.uuid !== checked,
        );
      })

      // Update scrollview
      setDatas(filteredData);

      // Reinitialize checker
      dispatch(checkerCancel(checkerControlName));
    }
  }

  // EFFECT

  // Id + auth
  useEffect(() => {
    if (id && collector?.uuid) {
      // Load category once
      fetchCategory(id);
      fetchCategorySearchFormFields(id);
      fetchCategorySearchSortFields(id);
    }
  }, [id, collector]);

  ////////// VIEWS //////////
  const PageTitle = () => {
    return (
      <>
        <i className={`icon ${category?.icon ? category.icon : 'ion-earth'}`}></i>
        <div>
          <h4>{category?.alias?.translatedName}</h4>
          <p className="mg-b-0">
            <Link
              className="tx-white-6"
              to={{
                pathname: `${isCurrentUser ? '' : '/collector/' + collector?.uuid}/collections`,
              }}>
              <span className='tx-success'>
                {isCurrentUser ? 
                  t('fe.collections.my.collections'):
                  t('fe.collections.collector.collections', {collector: collector})
                }
              </span>
            </Link>
            <span> - </span>
            {category?.alias?.parent?.translatedName}
          </p>
        </div>
      </>
    );
  }

  const renderItem = ({item}) => {
    return (
      <CheckWrapper
        key={item?.uuid}
        identifier={item.uuid} 
        checkerControl={checkerControlName}
        multiple={true}
      >
        {isCurrentUser ? (
          <ModalLink
            to={{
              pathname: `/item-modal/collection-item/details/${item.uuid}`,
            }}>
            <ItemCard data={item.item} collectionData={item} isList={false}/>
          </ModalLink>
        ):(
          <ModalLink
            to={{
              pathname: `/item-modal/item/details/${item.item.uuid}`,
            }}>
            <ItemCard data={item.item} isList={false}/>
          </ModalLink>
        )}
        
      </CheckWrapper>
    );
  }

  return (
    activityHook.isLoading ? <Loader /> :
    <ProtectedScreen condition={category}>
      <section>
        <ScrollListHeader
          titleComponent={<PageTitle />}
          infoBarShotcut={showInfoBar}
          orderComponent={<SearchOrder hook={searchFilterHook} fields={sortFields} />}
          filterComponent={<SearchFilter hook={searchFilterHook} fields={filterFields}/>}
        />
        <ScrollListCheckerHeader titleComponent={<PageTitle />} checkerControl={checkerControlName} />
        
        <div className="br-pagebody">
          <div className="row row-sm">
            <div className="col-lg-12">
              {collector?.uuid &&
                <ScrollList
                  urlCaller={{
                    url: CollectionAPI.getCollectionItems,
                    params: [collector.uuid, id, FormData.format(searchFilterHook.getFilters(), true), 1],
                    paginationIndex: 3,
                  }}
                  renderItem={renderItem}
                  extraData={searchFilterHook.getFilters() ?? id}
                  extraRefreshData={collectionItemState}
                  onRefreshData={(datas, setDatas) => (
                    isCurrentUser && collectionStateHandler(datas, setDatas)
                  )}
                  numColumns={'col-lg-3 col-md-4 col-6 mb-3'}
                  ListEmptyComponent={
                    <View className='col-12'>
                      <EmptyContent text={t('fe.no.result.description')} />
                    </View>
                  }
                  onScroll={onScroll}
                />
              }
            </div>
          </div>
        </div>
      </section>
    </ProtectedScreen>
  )
}

export default CollectionItems;