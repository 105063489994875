import { useState } from "react";
import useModalNavigate from "../../hooks/useModalNavigate";

const RequestItem = () => {
  const [code, setCode] = useState('');

  const modalNavigate = useModalNavigate();

  const submit = () => {
    modalNavigate('/item-modal/request-item/' + code);
  }

  return (
    <section>
      <div className="br-pagetitle mg-b-20-force pd-x-15 bg-black-1">
        <i className="icon ion-ios-search"></i>                            
        <div>
          <h4>Item request</h4>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6">
          <div className="form-layout form-layout-3">
            <div className="row no-gutters">
              <div className="col-12">
                <div className="form-group">
                  <input 
                    className="form-control tx-18" 
                    type="text" 
                    placeholder="Enter a barcode" 
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="form-layout-footer bd pd-20 bd-t-0">
              <button onClick={() => submit()} className="btn btn-info">Submit Form</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RequestItem;