import {fetchUtils} from './configs/fecthUtils';
import {api} from './configs/fetchConfig';
import {scanSchema} from './configs/schema';

export const ScanAPI = {
  getAll: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/scans?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  },
  getAllMissing: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
      '/api/protected-missing?' + newFilters.join('&'),
      {
        method: 'GET',
        headers: headers,
      },
    );
  },
  getMissing: async id => {
    return await api.fetchResource('/api/protected-missing/' + id, {
      method: 'GET',
    });
  },
  post: async (barcode) => {
    return await api.fetchResource('/api/scans', {
      method: 'POST',
      body: JSON.stringify(fetchUtils.bodyFormat([{'barcode': barcode}], scanSchema)),
    });
  },
  chooseItem: async (id, data) => {
    return await api.fetchResource('/api/scan/' + id + '/choose', {
      method: 'PUT',
      body: JSON.stringify(fetchUtils.bodyFormat(data, scanSchema)),
    });
  },
  updateMissing: async (id, data) => {
    return await api.fetchResource('/api/protected-missing/' + id + '/update', {
      method: 'PUT',
      body: JSON.stringify(fetchUtils.bodyFormat(data, scanSchema)),
    });
  },
  getByBarcode: async (barcode, page = 1) => {
    return await api.fetchResource(
      '/api/protected-missing/barcode/' + barcode + '/search?page=' + page,
      {
        method: 'GET',
      },
    );
  },
  getItems: async id => {
    return await api.fetchResource('/api/missing-barcodes/' + id + '/items', {
      method: 'GET',
    });
  },
};
