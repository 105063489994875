/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import { Controller, useForm } from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {loaderStart, loaderEnd} from '../../redux/Loader/loader-actions';
import FormData from "../../helpers/FormData";
import {CategoryBreadcrumbText, FormInputTag, FormInputTree, FormSelect, ItemThumb, Loader, Modal, ModalLink} from '..';
import { ScanAPI } from '../../apis/ScanAPI';
import { CategoryAPI } from '../../apis/CategoryAPI';
import { CommonAPI } from '../../apis/CommonAPI';
import Alert from '../../helpers/Alert';
import { ItemAPI } from '../../apis/ItemAPI';

const AssignationMissingSettings = ({id, onFinish}) => {
  const dispatch = useDispatch();

  const [activity, setActivity] = useState(true);
  const [missing, setMissing] = useState();

  const [categories, setCategories] = useState();
  const [barcodeInfo, setBarcodeInfo] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  // FORM
  const {
    control,
    handleSubmit,
    formState: {errors},
    getValues,
    setValue,
  } = useForm();
  
  const fetchMissing = async (missingId) => {
    setActivity(true);

    const response = await ScanAPI.getMissing(missingId);

    if (response?.status) {
      setMissing(response?.payload);
    }

    setActivity(false);
  };

  const fetchCategories = async () => {
    const response = await CategoryAPI.getAllProtected();

    if (response?.status) {
      setCategories(response?.payload);
    }
  };

  const fetchBarcodeInfo = async (barcode) => {
    const response = await ItemAPI.getBarcodeInfo(barcode);

    if (response?.status) {
      setBarcodeInfo(response?.payload);
    }
  };
  
  const setDefaultValues = async () => {
    setValue('category', missing?.category);
    setValue('assignedUsers', missing?.assignedUsers);
    setValue('status', missing?.status ?? '');
  };

  // On submit
  const onSubmit = async formData => {
    dispatch(loaderStart());

    var data = FormData.format(formData);

    var response = await ScanAPI.updateMissing(missing?.id, data);

    if (response?.status) {
      onFinish && onFinish(response?.payload);

      Alert.notify(
        'Well done! ',
        'Vos modifications ont été enregistrées.',
        'success',
      );
    }

    // Violations
    //setViolations(response?.violations);

    dispatch(loaderEnd());
  };

  // Load categories
  useEffect(() => {
    fetchMissing(id);
    fetchCategories();
  }, []);

  useEffect(() => {
    setDefaultValues();
    missing?.barcode && fetchBarcodeInfo(missing.barcode);
  }, [missing]);

  return (
    activity ? <Loader centerScreen={true} /> : (
      <div className="container">
        <div className='row'>
          <div className='col-12'>
            <div className="br-pagetitle mg-b-20-force pd-x-15 bg-black-1">
              <i className="icon ion-ios-search"></i>
              <div>
                <h4>Missing</h4>
                <p className="mg-b-0">
                  Missing n°{missing?.id}
                  {' > '}
                  <b>{missing?.barcode}</b>
                </p>
              </div>
              <div className="btn-group pos-absolute r-30">
                <button type="button" className="btn btn-outline-light rounded-0" onClick={handleSubmit(onSubmit)}><i className="fa fa-save"></i></button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <table className="table table-bordered table-colored">
              <tbody className="bg-black-1">
                <tr>
                  <td className="wd-20p bg-dark">
                    <span className="tx-white tx-14">Categorization</span>
                  </td>
                  <td>
                    <CategoryBreadcrumbText category={getValues().category} />
                    <li className="btn btn-outline-success pd-x-20 mg-l-10" onClick={() => setModalVisible(true)}>+</li>
                  </td>
                </tr>
                <tr>
                  <td className="wd-20p bg-dark">
                    <span className="tx-white tx-14">Assignation</span>
                  </td>
                  <td>
                    <Controller
                      control={control}
                      name="assignedUsers"
                      render={({ field }) => (
                        <FormInputTag 
                          {...field}
                          itemsFromUrl={{
                            url: CommonAPI.getProtectAdminUsers,
                            params: [[], 1],
                            filterIndex: 0,
                            searchingFilter: ['firstnameReference'],
                            paginationIndex: 1,
                          }}
                          labelSelector={'firstnameReference'}
                          idSelector={'uuid'}
                          containerStyle={{
                            borderWidth: 1,
                            borderColor: 'rgba(255, 255, 255, 0.08)',
                          }}
                          inputStyle={{color: '#fff'}}
                          multiple={true}
                        />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="wd-20p bg-dark">
                    <span className="tx-white tx-14">Status</span>
                  </td>
                  <td>
                    <Controller
                      control={control}
                      name="status"
                      render={({ field }) => (
                        <FormSelect
                          {...field}
                          items={missing?.statusList}
                          labelselector={'translatedName'}
                          idselector={'id'}
                        />
                      )}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {barcodeInfo?.items?.length > 0 &&
          <div className="row mg-t-20">
          {barcodeInfo?.items?.map(item => {
            return (
              <div className="col-6 col-sm-4 col-md-3 mg-b-10" key={item.uuid}>
                <ModalLink
                  to={{
                    pathname: `/item-modal/item/details/${(item.parentUuid || item.uuid)}`,
                  }}>
                  <ItemThumb data={item} />
                </ModalLink>
                <div className='text-center mg-t-5'>
                  <span className="tx-11 d-block">Created at: {(new Date(item?.createdAt)).toLocaleDateString()}</span>
                  <span className="tx-11 d-block">Created by: {item?.user?.firstnameReference}</span>
                </div>
              </div>
            );
          })}
          </div>}

        <Modal show={modalVisible} handleClose={() => setModalVisible(false)}>
          <Controller
            control={control}
            name="category"
            render={({ field }) => (
              <FormInputTree
                {...field}
                data={categories}
                idSelector={'id'}
                labelSelector={'translatedName'}
                submitOnSelect={false}
                onChange={(selection) => {
                  setModalVisible(false);
                  field.onChange(selection);
                }}
              />
            )}
          />
        </Modal>
      </div>
    )
  )
}

export default AssignationMissingSettings;