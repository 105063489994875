/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import { ItemAPI } from '../../apis/ItemAPI';
import useActivity from '../../hooks/useActivity';
import Loader from '../Loader/Loader';
import Media from '../Media/Media';
import { useTranslation } from 'react-i18next';
import View from '../View/View';

const TabCollectors = ({entity, onDataFetch}) => {
  const {t} = useTranslation();
  const activityHook = useActivity();

  // Collectors
  const [collectors, setCollectors] = useState();

  // Api
  const fetchItemCollectors = async id => {
    var activityRef = activityHook.start();

    const response = await ItemAPI.getCollectors(id);

    if (response.status) {
      setCollectors(response.payload);
    }

    activityHook.stop(activityRef);
  };

  // Effect
  // Anyway retrieve LinkedItems and Reviews and others
  useEffect(() => {
    fetchItemCollectors(entity.uuid);
  }, []);

  useEffect(() => {
    collectors?.length > 0 && onDataFetch();
  }, [collectors])

  return (
    activityHook.isLoading ? <Loader centerScreen={false} /> :
    <div className='container pd-0'>
      <div className='row'>
        <div className="col mg-t-40 tx-center">
          <h6>
            {t("fe.itemSheet.tabCollectors.title").toUpperCase()}
          </h6>
        </div>
      </div>
      <div className='row'>
        <div className="col mg-t-20">
          <div className="card shadow-base widget-5">
            <div className='row no-gutters'>
              {collectors?.map(collector => {
                return (
                  <View key={collector?.uuid} className='col-auto'>
                    <View className="mg-10">
                      <Media mediaUri={collector?.avatar?.contentUrl} avatar={true} size={80} alt={collector?.nickname} />
                    </View>
                  </View>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabCollectors;