import {memo, useState} from 'react';
import {Media} from '..';
import { Link } from 'react-router-dom';
import Text from '../../helpers/Text';

const ItemThumb = ({data, overlay = false, className = 'ht-250'}) => {
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <div 
      className="card bd-0"
      onMouseEnter={() => setShowOverlay(true)}
      onMouseLeave={() => setShowOverlay(false)}
    >
      <figure className={className}>
        <Media mediaUri={data?.cover?.contentUrl} mode="cover" alt={data?.fullName}/>
      </figure>
      {overlay && showOverlay && (
        <div className="pos-absolute l-0 r-0 b-0 t-0 bg-black-9">
          <div className='pd-10'>
            <div className="tx-13 tx-normal">
              <span className="tx-white hover-info">{Text.truncate((data?.name ?? ''), 120)}</span>
            </div>
            {data?.numberReferenceText && (
              <div className="tx-13 tx-warning">
                {data?.numberReferenceText}
              </div>
            )}
            {data?.subName && <span className="tx-10">{data.subName}</span>}
            <div className="tx-11 mg-t-5 tx-white">
              {data?.xContainer?.container?.translatedName}
            </div>
            {/* licence */}
            {/*
            {entity?.itemGroup && (
              <div className="tx-italic tx-secondary tx-9">Licence: <span className="tx-danger">{entity.itemGroup.name}</span></div>
            )}
            */}
          </div>
          <div className="pos-absolute mg-b-10 mg-r-30 b-0">
            <Link
              to={{pathname: `/browse/${data?.xContainer?.category?.alias?.id}`}}>
              <div className="tx-white tx-hover-info tx-9 lh-1">
                {data?.category?.translatedAliasParents?.map(translatedName => {
                  return translatedName + ' - ';
                })}
                {data?.xContainer?.category?.alias?.translatedName}
              </div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(ItemThumb);
