import React from 'react';
import {Link, Outlet, Route, Routes, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {AdminMenu, ProtectedRoute} from '../components';
import useCheckRole from '../hooks/useCheckRole';
import {
  NotFound,
  AdminDashboard,
  AdminCommand,
  AdminForms,
  AdminFormsContainerSettings,
  AdminFormsFieldsSettings,
  AdminFilters,
  AdminFiltersFieldsSettings,
  AdminSorters,
  AdminSortersFieldsSettings,
  AdminAssignationRequests,
  AdminAssignationMissing,
  AdminLicences,
  AdminLicencesList,
  AdminRequestItem,
  AdminRequestItemCode,
  AdminVerificationItems,
} from '.';

const AdminLayout = () => {
  const location = useLocation();
  
  const auth = useSelector(state => {
    return state.auth;
  });

  const checkRole = useCheckRole();

  return (
    <div className="Admin">
      <div className="wrapper">
        <header className="header">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="br-header">
                  <div className="br-header-left">
                    <div className="navicon-left d-lg-none">
                      <a id="btnLeftMenuMobile" href="">
                        <i className="icon ion-navicon-round"></i>
                      </a>
                    </div>
                    <div className="input-group d-none d-sm-inline-flex- wd-170 transition">
                      <span className="input-group-btn">
                          <button className="btn btn-secondary" type="button"><i className="fas fa-search"></i></button>
                      </span>
                    </div>    
                    <div className="bg-black-2 pd-x-20 align-items-center justify-content-start d-none d-lg-flex">
                      <Link to={'/'} className="pd-t-10 pd-b-10">
                        <h4 className="mg-b-0 tx-uppercase tx-bold tx-spacing--2 tx-white mg-r-20 tx-poppins">
                          Administration
                        </h4>
                      </Link>
                    </div>
                  </div>
                  <div className="br-header-right">
                    <nav className="nav">
                      <Link className="nav-link" to="/administration/request-item">
                        <i className="icon ion-ios-plus tx-24"></i>
                      </Link>
                      <Link className="nav-link" to={'/'}>
                        <i className="icon ion-android-exit tx-24"></i>
                      </Link>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="container mg-t-20">
          <div className="row">
            <div className="col-12">
              <main className="br-mainpanel">
                <div className="row">
                  <div className="col-12 col-lg-2">
                    <AdminMenu />
                  </div>
                  <div className="col-12 col-lg-10">
                    <Routes location={location?.state?.backgroundLocation || location}>
                      {/* Simple routes */}
                      <Route index path="dashboard" element={<AdminDashboard />} />
                      <Route path="forms" element={<Outlet />}>
                        <Route index path="*" element={<AdminForms />} />
                        <Route path=":id/container/settings" element={<AdminFormsContainerSettings />} />
                        <Route path="container/:id/fields/settings" element={<AdminFormsFieldsSettings />} />
                      </Route>
                      <Route path="filters" element={<Outlet />}>
                        <Route index path="*" element={<AdminFilters />} />
                        <Route path=":id/fields/settings" element={<AdminFiltersFieldsSettings />} />
                      </Route>
                      <Route path="sorters" element={<Outlet />}>
                        <Route index path="*" element={<AdminSorters />} />
                        <Route path=":id/fields/settings" element={<AdminSortersFieldsSettings />} />
                      </Route>
                      <Route path="request-item" element={<AdminRequestItem />} />
                      <Route path="request-item/:code" element={<AdminRequestItemCode />} />
                      <Route path="licences" element={<Outlet />}>
                        <Route index path="*" element={<AdminLicences />}/>
                        <Route path=":category_id/list" element={<AdminLicencesList />} />
                      </Route>
                      <Route path="assignation" element={<Outlet />}>
                        <Route path="requests" element={<Outlet />}>
                          <Route index path="*" element={<AdminAssignationRequests />} />
                        </Route>
                        <Route path="missing" element={<Outlet/>}>
                          <Route index path="*" element={<AdminAssignationMissing />} />
                        </Route>
                      </Route>
                      <Route path="verification" element={<Outlet />}>
                        <Route path="items" element={<Outlet />}>
                          <Route index path="*" element={<AdminVerificationItems />} />
                        </Route>
                      </Route>
                      <Route path="commands" element={<ProtectedRoute role={'ROLE_SUPER_ADMIN'} />}>
                        <Route index path="*" element={<AdminCommand />} />
                      </Route>
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLayout;
