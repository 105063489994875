import React from 'react';
import {Link} from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import useCheckRole from '../../hooks/useCheckRole';
import Media from '../Media/Media';
import { useTranslation } from 'react-i18next';

const UserMenu = ({user}) => {
  const checkRole = useCheckRole();
  const {t} = useTranslation();

  return (
    <Dropdown className="dropdown">
      <Dropdown.Toggle as={"div"} bsPrefix="p-0">
        <span className="btn nav-link nav-link-profile">
          <Media mediaUri={user?.avatar?.contentUrl} avatar={true} size={32} alt={user?.nickname}/>
          <span className="square-10 bg-success"></span>
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-header" x-placement="bottom-start">
        <Dropdown.Header>
          <div className="d-flex align-items-center justify-content-center">
            <Link to={"/account/profile"}>
              <Media mediaUri={user?.avatar?.contentUrl} avatar={true} size={80} alt={user?.nickname}/>
            </Link>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <h6 className="logged-fullname">{user?.nickname}</h6>
            {/*<p>Crosslud.contact@gmail.com</p>*/}
          </div>
        </Dropdown.Header>
        <hr />
        <ul className="list-unstyled user-profile-nav">
          <li><Dropdown.Item as={Link} to={'/collections'}><i className="icon ion-ios-folder"></i> {t('fe.collections.link')}</Dropdown.Item></li>
          {/*<li><Dropdown.Item as={Link} to=""><i className="icon ion-ios-star"></i> Favorites</Dropdown.Item></li>*/}
          <li><Dropdown.Item as={Link} to={'/account/profile'}><i className="icon ion-ios-person"></i> {t('fe.account.link')}</Dropdown.Item></li>
          <li><Dropdown.Item as={Link} to={'/collections/scans'}><i className="icon ion-clipboard"></i> {t('fe.scan.link')}</Dropdown.Item></li>
          {checkRole.isGranted('ROLE_ADMIN') &&
            <>
              <hr />
              <li><Dropdown.Item as={Link} to={'/administration/dashboard'}><i className="icon ion-ios-cog"></i> {t('fe.administration.link')}</Dropdown.Item></li>
            </>
          }
          <hr />
          <li>
            <Dropdown.Item as={Link} to={'/logout'}>
              <i className="icon ion-power"></i> 
              {t('fe.sign.out.link')}
            </Dropdown.Item>
          </li>
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default UserMenu;