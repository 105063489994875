import {useDispatch} from 'react-redux';
import { useTranslation } from 'react-i18next';
import Alert from '../helpers/Alert';
import { useNavigate } from 'react-router';
import { loaderEnd, loaderStart } from '../redux/Loader/loader-actions';
import { collectionAddItem, collectionDeleteItem } from '../redux/CollectionItem/collectionItem-actions';
import { CollectionAPI } from '../apis/CollectionAPI';

const useItemHelper = (entity, setEntity, collectionEntity, setCollectionEntity) => {
  const {t} = useTranslation();

  const navigate = useNavigate();

  // DISPACTH
  const dispatch = useDispatch();

  // Go authentication ok
  const goToAuhentication = () => {
    Alert.alert(
      t('fe.authentication.confim.title'), 
      t('fe.authentication.confim.description'),
      [
        {
          text: t('fe.confirm.button'),
          onPress: () => {
            navigate('/sign-in');
          },
          style: 'confirm',
        },
        {
          text: t('fe.cancel.button'),
          style: 'cancel',
        },
      ],
    );
  };

  // Add to collection
  const collectionAddItemHandler = id => {
    const alertMessage =
      entity?.quantityInUserCollection > 0
        ? t('fe.itemHelper.collectionAdd.confim.description.already.in', { quantity: entity?.quantityInUserCollection }) 
        : t('fe.itemHelper.collectionAdd.confim.description');

    Alert.alert(t('fe.itemHelper.collectionAdd.confim.title'), alertMessage, 
      [
        {
          text: t('fe.confirm.button'),
          onPress: () => {
            confirmCollectionAddItemHandler(id);
          },
          style: 'confirm',
        },
        {
          text: t('fe.cancel.button'),
          style: 'cancel',
        },
    ]);
  };

  // Confirm add to collection
  const confirmCollectionAddItemHandler = async id => {
    dispatch(loaderStart());

    var response = await CollectionAPI.addItem(id);

    if (response?.status) {
      // Only update collection entity with new data
      // when we are in a collection
      if (collectionEntity) {
        setCollectionEntity(response?.payload);
      }
      
      setEntity(response?.payload?.item);

      Alert.notify(
        t('fe.itemHelper.collectionAdd.alert.success.title'),
        t('fe.itemHelper.collectionAdd.alert.success.description'),
        'success',
      );

      dispatch(collectionAddItem(response?.payload));
    }

    dispatch(loaderEnd());
  };

  // Delete from collection
  const collectionDeleteItemHandler = id => {
    Alert.alert(
      t('fe.itemHelper.collectionDelete.confirm.title'),
      t('fe.itemHelper.collectionDelete.confirm.description'),
      [
        {
          text: t('fe.confirm.button'),
          onPress: () => {
            confirmCollectionDeleteItemHandler(id);
          },
          style: 'confirm',
        },
        {
          text: t('fe.cancel.button'),
          style: 'cancel',
        },
      ],
    );
  };

  // Confirm delete from collection
  const confirmCollectionDeleteItemHandler = async id => {
    dispatch(loaderStart());

    const response = await CollectionAPI.deleteCollectionItem(id);

    if (response.status) {
      Alert.notify(
        t('fe.itemHelper.collectionDelete.alert.info.title'),
        t('fe.itemHelper.collectionDelete.alert.info.description'),
        'info',
      );

      dispatch(collectionDeleteItem(collectionEntity));
    }

    dispatch(loaderEnd());
  };

  return {
    collectionAddItemHandler: collectionAddItemHandler,
    collectionDeleteItemHandler: collectionDeleteItemHandler,
    goToAuhentication: goToAuhentication
  };
};

export default useItemHelper;
