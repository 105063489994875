/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import useActivity from "../../hooks/useActivity";
import Loader from "../Loader/Loader";

const LoadMore = React.forwardRef(({
  itemsFromUrl,
  children,
  extraData,
  onDataCount,
}, ref) => {
  const activityHook = useActivity();

  const [page, setPage] = useState();
  const [endPagination, setEndPagination] = useState(false);
  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [paginator, setPaginator] = useState();

  // ACTION & API
  const callDatasUrl = async () => {
    var activityRef = activityHook.start();

    if (itemsFromUrl?.url && itemsFromUrl?.params) {
      if (itemsFromUrl?.paginationIndex) {
        itemsFromUrl.params[itemsFromUrl.paginationIndex] = page;
      } else {
        setEndPagination(true);
      }

      const response = await itemsFromUrl.url.apply(this, itemsFromUrl.params);

      if (response.status) {
        setTotalItems(response?.totalItems);
        setPaginator(response?.paginator);

        var newResponseData = itemsFromUrl?.transformData
          ? itemsFromUrl?.transformData(response?.payload)
          : response?.payload;

        if (page === 1) {
          setItems(newResponseData);
        } else {
          setItems([...items].concat(newResponseData));
        }

        if (newResponseData.length > 0) {
          setEndPagination(false);
        } else {
          setEndPagination(true);
        }
      }
    }

    activityHook.stop(activityRef);
  };

  const LoadMoreButtonComponent = ({title = 'Load more...'}) => {
    return (
      paginator?.next && (
        <div className="container pd-0">
          <div className="row">
            <div className='col-12'>
              {!endPagination && (
                activityHook.isLoading ? 
                  <Loader centerScreen={false} /> :
                  <button className="btn btn-outline-secondary mg-t-10 mg-b-20" onClick={() => setPage(page + 1)}>{title}</button>
              )}
            </div>
          </div>
        </div>
      )
    )
  }

  const render = () => {
    if (typeof children !== 'function') {
      console.log('Component <LoadMore> children prop must be a function!');

      return null;
    }

    return (
      <>
        {page === 1 && activityHook.isLoading && <Loader centerScreen={false} />}
        {children({items, LoadMoreButtonComponent})}
      </>
    )
  }

  useEffect(() => {
    page && callDatasUrl();
  }, [page]);

  // EndPoint
  useEffect(() => {
    setPage(1);
    callDatasUrl();
  }, [extraData]);

  useEffect(() => {
    totalItems && onDataCount && onDataCount(totalItems)
  }, [totalItems])

  return render();
})

export default LoadMore;