import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import { NotificationAPI } from '../../apis/NotificationAPI';
import { useSelector } from 'react-redux';

const NotificationBell = () => {
  const notificationState = useSelector(state => {
    return state.notification;
  });

  const [totalItems, setTotalItems] = useState();

  const fetchNotifications = async () => {
    const response = await NotificationAPI.getUnreadList();

    if (response?.status) {
      setTotalItems(response?.totalItems);
    }
  };

  // Load users requests
  useEffect(() => {
    fetchNotifications();
  }, [notificationState]);

  return (
    <Link className="nav-link pd-x-7 pos-relative" to={'/history/notifications'}>
      <i className="icon ion-ios-bell-outline tx-24">
        {totalItems > 0 &&
          <>
            <span className='pos-absolute tx-10 tx-white tx-bold'>{totalItems > 99 ? '99+' : totalItems}</span>
            <span className="square-10 rounded-circle bg-danger"/>
          </>
        }
      </i>
    </Link>
  )
}

export default NotificationBell;