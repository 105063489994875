/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {useState, useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {useDispatch, useSelector} from 'react-redux';
import {
  itemUpdate,
} from '../../redux/Item/item-actions';
import {loaderStart, loaderEnd} from '../../redux/Loader/loader-actions';
import {FormInputMedia, FormInputText, FormInputDate, FormInputTag, FormInputCheck, FormInputObject, Loader, ModalLink, FormSelect, CategoryBreadcrumbText} from '../../components';
import FormData from "../../helpers/FormData";
import Field from "../../helpers/Field";
import useCheckRole from "../../hooks/useCheckRole";
import useModalNavigate from "../../hooks/useModalNavigate";
import { ItemAPI } from "../../apis/ItemAPI";
import { CategoryAPI } from "../../apis/CategoryAPI";
import Alert from "../../helpers/Alert";
import useActivity from "../../hooks/useActivity";
import { CommonAPI } from "../../apis/CommonAPI";

const ItemDetailsEdit = () => {
  // PARAMS
  const {id} = useParams();

  const activityHook = useActivity();

  // FORM
  const {
    control,
    handleSubmit,
    formState: {errors},
    getValues,
    setValue,
    watch,
  } = useForm();

  // NAVIGATE
  const modalNavigate = useModalNavigate();

  // DISPATCH
  const dispatch = useDispatch();

  // STATE
  const [entity, setEntity] = useState();
  const [violations, setViolations] = useState([]);
  const [linkedItems, setLinkedItems] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [xContainerMainFields, setXContainerMainFields] = useState([]);
  const [xContainerOptionalFields, setXContainerOptionalFields] = useState([]);

  // SELECTOR
  const auth = useSelector(state => {
    return state.auth;
  });

  // ROLE
  const checkRole = useCheckRole();

  // ACTION & API

  const goItemDetails = () => {
    modalNavigate('/item-modal/item/details/' + (entity?.parentUuid || entity?.uuid));
  }

  // On submit
  const onSubmit = async formDatas => {
    dispatch(loaderStart());

    var datas = FormData.format(formDatas);

    // PUT
    if (entity?.uuid) {
      const response = await ItemAPI.update(entity.uuid, datas);
      
      if (response?.status) {
        Alert.notify(
          'Well done! ',
          'Vos modifications ont été enregistrées.',
          'success',
        );

        dispatch(itemUpdate(response?.payload, {}));

        goItemDetails();
      }

      // Violations
      setViolations(response?.violations);
    }

    dispatch(loaderEnd());
  };

  // Item statuses
  const fetchItemStatuses = async () => {
    const response = await CommonAPI.getItemStatuses()

    if (response.status) {
      setStatuses(response?.payload);
    }
  };
  
  // Linked items
  const fetchItemLinkedItems = async id => {
    const response = await ItemAPI.getLinkedItems(id);

    if (response.status) {
      setLinkedItems(response?.payload);
    }
  };

  // Fields
  const fetchCategoryXContainersFields = async (xContainerId) => {
    const activityRef = activityHook.start();

    const response = await CategoryAPI.getXContainersFields(xContainerId);

    if (response.status) {
      var mains = [];
      var optionals = [];

      response?.payload?.map((res) => {
        if (res?.scope === 'main') {
          mains.push(res);
        }

        if (res?.scope === 'optional') {
          optionals.push(res);
        }
      });

      setXContainerMainFields(mains);
      setXContainerOptionalFields(optionals);
    }

    activityHook.stop(activityRef);
  };

  // Fetch protected item
  const fetchProtectedItem = async id => {
    const response = await ItemAPI.getProtected(id);

    if (response.status) {
      setEntity(response?.payload);
    }
  };

  const formFieldRender = formField => {
    const fieldData = Field.fieldsDataLoader(formField, entity?.xContainer?.category?.id);

    setValue(formField.reference, Field.getDefaultDatas(entity, formField) || "");

    return (
      <Controller
        control={control}
        name={formField.reference}
        render={({ field }) =>
          <>
            {['manyToOne', 'manyToMany'].includes(formField?.mappingType) && (
              <FormInputTag
                {...field}
                violation={violations[formField.reference]}
                itemsFromUrl={fieldData.urlParams}
                idSelector={fieldData.selector.id}
                labelSelector={fieldData.selector.label}
                containerStyle={{
                  borderWidth: 1,
                  borderColor: 'rgba(255, 255, 255, 0.08)',
                }}
                inputStyle={{color: '#fff'}}
                multiple={formField.mappingType === 'manyToMany'}
              />
            )}

            {['field'].includes(formField?.mappingType) && (
              <>
                {['string', 'text'].includes(formField?.fieldType) && (
                  <FormInputText
                    {...field}
                    violation={violations[formField.reference]}
                    multiline={formField.fieldType === 'text'}
                  />
                )}

                {['datetime'].includes(formField?.fieldType) && (
                  <FormInputDate
                    {...field}
                    violation={violations[formField.reference]}
                    containerStyle={{
                      borderWidth: 1,
                      borderColor: 'rgba(255, 255, 255, 0.08)',
                    }}
                    inputStyle={{color: '#fff'}}
                  />
                )}

                {['boolean'].includes(formField?.fieldType) && (
                  <FormInputCheck
                    {...field}
                    violation={violations[formField.reference]}
                  />
                )}
              </>
            )}
          </>
        }
      />
    );
  };

  const formRowRender = (categoryContainerField) => {
    return (
      categoryContainerField?.formField.fieldType !== 'boolean' ? (
        <>
          <label>{categoryContainerField?.translatedName ? categoryContainerField.translatedName : <>&nbsp;</>}</label>
          <div className="form-group">
            {formFieldRender(categoryContainerField?.formField)}
          </div>
        </>
      ): (
        <label className="ckbox">
          {formFieldRender(categoryContainerField?.formField)}
          <span>{categoryContainerField?.translatedName ? categoryContainerField.translatedName : <>&nbsp;</>}</span>
        </label>
      )
    );
  };

  // EFFECT

  // Load item
  useEffect(() => {
    if (auth?.isLoggedIn && id) {
      fetchProtectedItem(id);
      fetchItemStatuses();
    }
  }, [auth]);

  useEffect(() => {
    if (entity?.uuid) {
      fetchItemLinkedItems(entity.uuid);
      fetchCategoryXContainersFields(entity?.xContainer?.id);

      setValue('status', entity?.status ?? '');
    }
  }, [entity]);
  
  // RENDER
  return (
    activityHook.isLoading ? <Loader centerScreen={true}/> : (
      <div className='container'>
        <div className='row'>
          <div className="col">
            <div className="br-pagetitle mg-b-20-force pd-x-15 bg-black-1">
              <i className={`icon ${entity?.xContainer?.category?.icon ? entity?.xContainer?.category.icon : 'ion-earth'}`}></i>                                                       
              <div>
                <h4>Item Details</h4>
                <p className="mg-b-0">
                  <Link
                    to={{
                      pathname: `/browse/${entity?.xContainer?.category?.alias?.parent?.id}`,
                    }}>
                    {entity?.xContainer?.category?.alias?.parent?.translatedName}
                  </Link>
                  {' - '}
                  <Link
                    to={{
                      pathname: `/browse/${entity?.xContainer?.category?.alias?.id}`,
                    }}>
                    {entity?.xContainer?.category?.alias?.translatedName}
                  </Link>
                  {' - '}
                  <Link
                    className="tx-white-6"
                    to={{
                      pathname: `/item-modal/item/details/${entity?.uuid}`,
                    }}>
                    {entity?.name}
                  </Link>
                </p>
              </div>
              {/*
              <div className="btn-group pos-absolute r-30">
                <button type="button" className="btn btn-outline-light rounded-0"><i className="fa fa-th"></i></button>
                <button type="button" className="btn btn-outline-light rounded-0"><i className="fa fa-th-list"></i></button>
              </div>
              */}
            </div>

            {checkRole.isGranted('ROLE_ADMIN') && (
            <div className="row justify-content-center">
              <div className="col-12 mg-b-50">
                <table className="table table-bordered table-colored">
                  <thead className="thead-dark bg-transparent">
                    <tr>
                      <th className="wd-20p">Code</th>
                      <th className="wd-25p">Category</th>
                      <th className="wd-25p">Container</th>
                      <th className="wd-25p">Licence</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="bg-black-1">
                    <tr>
                      <td>
                        <span className="tx-info">{entity?.barcode}</span>
                      </td>
                      <td>
                        <CategoryBreadcrumbText category={entity?.xContainer?.category}/>
                      </td>
                      <td>
                        {entity?.xContainer?.container?.translatedName}
                      </td>
                      <td>
                        {entity?.itemGroup?.completeName}
                      </td>
                      <td>
                        <ModalLink to={`/item-modal/item-header-edit/${entity?.uuid}`}><span className="tx-success">Modifier</span></ModalLink>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            )}
            <div className="row justify-content-center">
              {violations?.error && <div className="col-12 pd-b-40 text-center tx-16"><span className='text-danger'>{violations?.error.message}</span></div>}
              <div className="col-sm-12 col-md-12 col-lg-4">
                <Controller
                  control={control}
                  name={'files'}
                  render={({ field }) =>
                    <FormInputMedia
                      {...field}
                      items={entity?.files}
                      value={entity?.cover}
                      containerStyle={{
                        borderWidth: 1,
                        borderColor: 'rgba(255, 255, 255, 0.08)',
                      }}
                      inputStyle={{color: '#fff'}}
                    />
                  }
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-8">
                <div className="row">
                  {xContainerMainFields && xContainerMainFields?.map((categoryContainerField, index) => {
                    return (
                      <div key={index} className="col-md-6 col-lg-6">
                        <div className="row no-gutters">
                          <div className="col">
                            {formRowRender(categoryContainerField)}
                          </div>
                          {categoryContainerField?.categoryContainerXFormFields?.map((subCategoryContainerField, index) => {
                            return (
                              <div key={index} className="col-3">
                                {formRowRender(subCategoryContainerField)}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                </div>
                {xContainerOptionalFields && xContainerOptionalFields.length > 0 && (
                  <>
                    <hr className="bd-t mg-y-25" />
                    <div className="row">
                      {xContainerOptionalFields?.map((categoryContainerField, index) => {
                        return (
                          <div key={index} className="col-md-6 col-lg-6">
                            <div className="row no-gutters">
                              <div className="col">
                                {formRowRender(categoryContainerField)}
                              </div>
                              {categoryContainerField?.categoryContainerXFormFields?.map((subCategoryContainerField, index) => {
                                return (
                                  <div key={index} className="col-3">
                                    {formRowRender(subCategoryContainerField)}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}

                <p className="lead pd-20 bg-primary tx-white mg-t-50">
                  Associer des objets
                </p>

                <div className="row">
                  <div className="col">
                    <Controller
                      control={control}
                      name={'linkedItems'}
                      render={({ field }) =>
                        <FormInputObject
                          {...field}
                          value={linkedItems}
                          containerStyle={{
                            borderWidth: 1,
                            borderColor: 'rgba(255, 255, 255, 0.08)',
                          }}
                          inputStyle={{color: '#fff'}}
                        />
                      }
                    />
                  </div>
                </div>

                <p className="lead pd-20 bg-danger tx-white mg-t-50">
                  Status
                </p>

                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <FormSelect
                      {...field}
                      items={statuses}
                      labelselector={'name'}
                      idselector={'id'}
                    />
                  )}
                />

                <div className="row mg-t-50">
                  <div className="col-12 text-right">
                    <button className="btn mg-r-10" onClick={(e) => goItemDetails()}>
                      <span>Cancel</span>
                      <span className="icon mg-l-5"><i className="ion-paper-airplane"></i></span>
                    </button>
                    <button className="btn btn-primary" onClick={handleSubmit(onSubmit)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default ItemDetailsEdit;