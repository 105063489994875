/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import Utils from '../../helpers/Utils';

const FormSelect = React.forwardRef((props, ref) => {
  const [datas, setDatas] = useState([]);
  const [selectValues, setSelectValues]= useState([]);
  const [changeDetector, setChangeDetector] = useState();

  // Call URL
  const callDatasUrl = async () => {
    if (
      props.itemsfromurl &&
      props.itemsfromurl.url &&
      props.itemsfromurl.params &&
      props.itemsfromurl.paginationIndex
    ) {
      const response = 
        await props.itemsfromurl.url.apply(this, props.itemsfromurl.params);

      if (response.status) {
        setDatas(response?.payload);
      }
    }
  };

  // Set default values
  const setDefaultValue = () => {
    if (props.value) {
      var formatDefaultValue = [];
      var values = props.value;

      if (!Utils.isArray(props.value)) {
        values = [props.value];
      }

      for (var v in values) {
        var formattedValue = values[v];

        if (Utils.isObject(formattedValue)) {
          formattedValue = values[v][props.idselector];
        }

        formatDefaultValue.push(formattedValue);
      }

      setSelectValues(formatDefaultValue);
    }
  };

  // UseEffect
  // Default value
  useEffect(() => {
    setDefaultValue();
  }, [props.value]);

  useEffect(() => {
    setDatas(props.items);
  }, [props.items]);

  // Main
  useEffect(() => {
    callDatasUrl();
  }, [props.itemsfromurl]);

  // Change detector
  useEffect(() => {
    var detectOnChange = '';

    selectValues.map(selectValue => {
      detectOnChange = detectOnChange + '' + selectValue;
    });

    if (changeDetector !== detectOnChange) {
      setChangeDetector(detectOnChange);
    }
  }, [selectValues]);

  // Update HookForm
  useEffect(() => {
    if (!changeDetector)
      return;

    var formatedDatas = [];

    selectValues.map(selectValue => {
      formatedDatas.push(selectValue);
    });

    props.onChange && props.onChange(props?.multiple ? formatedDatas : (formatedDatas?.length > 0 ? formatedDatas[0] : null));
  }, [changeDetector]);

  return (
    <select {...props} className="form-control form-control-dark">
      <option value={""}>{props?.placeholder ?? 'Please select a value'}</option>
      {datas?.map(data => {
        return (
          <option key={data[props.idselector]} value={data[props.idselector]}>{data[props.labelselector].toString().trim()}</option>
        );
      })}
    </select>
  );
})

export default FormSelect;
