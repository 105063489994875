import {Outlet, useLocation, useNavigate} from "react-router-dom";
import { Modal } from "../../components";

const ModalLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleModalClose = () => {
    return navigate(location?.state?.backgroundLocation || '/');
  }

  return (
    <Modal 
      show={true} 
      fullscreen={true} 
      centered={false} 
      handleClose={() => handleModalClose()}
      contentClassName={'col'}
      closeButton={true}
    >
      <Outlet />
    </Modal>
  );
};

export default ModalLayout;