import {Navigate} from "react-router-dom";
import { NotFound } from "../../screens";

const ProtectedScreen = ({children, condition = false, redirect = false}) => {
  return (
    condition ? children : (!redirect ? <NotFound /> : <Navigate to={'/'} replace />)
  );
};

export default ProtectedScreen;
