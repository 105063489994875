// import {Link, resolvePath, useLocation} from "react-router-dom";
import useModalNavigate from "../../hooks/useModalNavigate";

/*
const ModalLink = (props) => {
  const location = useLocation();
  const resolvedPath = resolvePath(props.to);

  var computedLocation = null;

  if (resolvedPath?.pathname.match(/-modal\//)) {
    computedLocation = {backgroundLocation: location.state?.backgroundLocation || location}
  }

  return (
    <Link
      {...props}
      state={computedLocation}>
      {props.children}
    </Link>
  );
};*/

const ModalLink = (props) => {
  const navigate = useModalNavigate();

  return (
    <div {...props} onClick={(e) => navigate(props.to)} className="cursor-pointer">
      {props.children}
    </div>
  );
};

export default ModalLink;
