import { api } from "./configs/fetchConfig";
import { fetchUtils } from "./configs/fecthUtils";
import { itemLicenceSchema, itemSchema, itemGroupSchema } from "./configs/schema";

export const LicenceAPI = {
    create: async data => {
      return await api.fetchResource('/api/item-groups', {
        method: 'POST',
        body: JSON.stringify(fetchUtils.bodyFormat(data, itemLicenceSchema)),
      });
    },
    update: async (id, data) => {
      return await api.fetchResource('/api/item-groups/' + id, {
        method: 'PUT',
        body: JSON.stringify(fetchUtils.bodyFormat(data, itemSchema)),
      });
    },
    getAll: async (filters = [], page = 1) => {
      const newFilters = filters.concat('page=' + page);

      var headers = new Headers();
      headers.set('Accept', 'application/ld+json');

      return await api.fetchResource('/api/item-groups?' + newFilters.join('&'), {
          method: 'GET',
          headers: headers,
      });
    },
    get: async id => {
        return await api.fetchResource('/api/item-groups/' + id, {
            method: 'GET',
        });
    },
    getItems: async (id, filters = [], page = 1) => {
      const newFilters = filters.concat('page=' + page);

      var headers = new Headers();
      headers.set('Accept', 'application/ld+json');

      return await api.fetchResource('/api/item-groups/' + id + '/items?' + newFilters.join('&'), {
          method: 'GET',
          headers: headers,
      });
    },
    getItemGroups: async (id, filters = [], page = 1) => {
      const newFilters = filters.concat('page=' + page);

      var headers = new Headers();
      headers.set('Accept', 'application/ld+json');

      return await api.fetchResource('/api/item-groups/' + id + '/item-groups?' + newFilters.join('&'), {
          method: 'GET',
          headers: headers,
      });
    },
    post: async (data) => {
      return await api.fetchResource('/api/item-groups', {
        method: 'POST',
        body: JSON.stringify(fetchUtils.bodyFormat(data, itemGroupSchema)),
      });
    },
    postItemGroup: async (id, data) => {
      return await api.fetchResource('/api/item-groups/' + id, {
        method: 'POST',
        body: JSON.stringify(fetchUtils.bodyFormat(data, itemGroupSchema)),
      });
    },
    put: async (id, data) => {
      return await api.fetchResource('/api/item-groups/' + id, {
        method: 'PUT',
        body: JSON.stringify(fetchUtils.bodyFormat(data, itemGroupSchema)),
      });
    },
    delete: async (id) => {
      return await api.fetchResource('/api/item-groups/' + id, {
        method: 'DELETE'
      });
    },
}