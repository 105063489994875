import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {userLogout} from '../../redux/Auth/auth-actions';
import { CommonAPI } from '../../apis/CommonAPI';

const Logout = () => {
  const dispatch = useDispatch();

  const unsetTokens = async () => {
    const response = await CommonAPI.logout();

    if (response?.status) {
      dispatch(userLogout());

      window.location.href = '/';
    }
  }
  
  useEffect(() => {
    unsetTokens();
  });

  return <div>Logout...</div>;
}

export default Logout;