import { api } from "./configs/fetchConfig";
import { fetchUtils } from "./configs/fecthUtils";
import { itemCollectionSchema, itemSchema } from "./configs/schema";

export const CollectionAPI = {
  getAll: async (user) => {
    return await api.fetchResource('/api/users/' + user + '/collections', {
      method: 'GET',
    });
  },
  get: async (user, category) => {
    return await api.fetchResource('/api/users/' + user + '/collections/' + category, {
      method: 'GET',
    });
  },
  getStatus: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    return await api.fetchResource('/api/item-collection-statuses?' + newFilters.join('&'), {
      method: 'GET',
    });
  },
  getCollectionItems: async (
    user,
    category,
    filters = [],
    page = 1,
  ) => {
    let query = fetchUtils.customQueryFilterFormat(filters, itemSchema).join('&');

    let q = '?page=' + page + (query && '&' + query);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/users/' + user + '/collections/' + category + '/items' + q, {
      method: 'GET',
      headers: headers
    });
  },
  getCollectionItem: async id => {
    return await api.fetchResource('/api/item-collections/' + id, {
      method: 'GET',
    });
  },
  addItem: async id => {
    return await api.fetchResource('/api/item-collections', {
      method: 'POST',
      body: JSON.stringify({
        item: '/api/items/' + id,
      }),
    });
  },
  updateCollectionItem: async (id, purchasedAt, note, price, status, currency) => {
    var data = {
      note: note,
      purchasedAt: purchasedAt,
      price: price,
      status: status,
      currency: currency
    }

    return await api.fetchResource('/api/item-collections/' + id, {
      method: 'PUT',
      body: JSON.stringify(fetchUtils.bodyFormat(data, itemCollectionSchema)),
    });
  },
  deleteCollectionItem: async id => {
    return await api.fetchResource('/api/item-collections/' + id, {
      method: 'DELETE'
    });
  },
  batchDeleteCollectionItem: async uuids => {
    return await api.fetchResource('/api/item-collections/batch/delete', {
      method: 'POST',
      body: JSON.stringify({
        uuids: uuids,
      }),
    });
  },
}